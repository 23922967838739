import axios from "axios";

const configAxios = () => {
  // axios.interceptors.
  axios.defaults.baseURL = "https://voiiice.com/api/";
  // axios.defaults.baseURL = "https://44.207.22.35/api/";
  // axios.defaults.baseURL = "http://127.0.0.1:5000/api/";

  // axios.interceptors.request.use((config) => {
  //   const token = localStorage.getItem("auth_token") ?? false;
  //   return {
  //     ...config,
  //     headers: {
  //       ...(token && { Authorization: `${token}` }),
  //       ...config.headers,
  //     },
  //   };
  // });
  // axios.interceptors.response.use(
  //   (res) => {
  //     console.log(res);
  //     if (res.config.url === "/auth/signIn" && res.data.success) {
  //       console.log(res.config.url);
  //       console.log(res.data.success);
  //       localStorage.setItem("auth_token", res.data.token);
  //     }
  //     return res;
  //   },
  //   (error) => {
  //     if (error.response.status === 401) {
  //       console.log(`unauthorized :)`);
  //       localStorage.removeItem("auth_token");
  //       // window.location.href = "/login";
  //     }
  //     return { data: { success: false } };
  //   }
  // );
};

export default configAxios;
