import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import useWindowWidth from "../../hooks/use-width";
import SwipeImg1 from "../../icons/img/swipe-img-1.png";
import SwipeImg2 from "../../icons/img/swipe-img-2.png";
import SwipeImg3 from "../../icons/img/swipe-img-3.png";

import "swiper/css";

import "./swipe.css";

const SwipeComponent = () => {
  const width = useWindowWidth();

  return (
    <Swiper
      slidesPerView={width > 800 ? 2.5 : 1}
      centeredSlides={width > 800 ? true : false}
      spaceBetween={width > 800 ? 100 : 0}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="swiper-details">
          <h1>Get recordings from any device through any browser</h1>
          <img src={SwipeImg1} alt="swipe img 1" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-details">
          <h1>Listen to the recordings and download them</h1>
          <img src={SwipeImg2} alt="swipe img 2" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-details">
          <h1>Style the widget according to your website/brand</h1>
          <img src={SwipeImg3} alt="swipe img 3" />
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default SwipeComponent;
