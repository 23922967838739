import { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { SnackBarContext } from "../../../providers/snackbar/context";
import { soundAllowed } from "./visualizer";

function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
}

const RecordWave = ({
  micState,
  setRecState,
  setBlobObj,
  setFileObj,
  setMicState,
  timeAllowed,
  locObj,
}) => {
  const chunks = useRef([]);
  const [streamObj, setStreamObj] = useState(null);
  const [mediaObj, setMediaObj] = useState(null);
  const [time, setTime] = useState(0);
  const [timeFormatted, setTimeFormatted] = useState("00:00");
  const [shouldStart, setShouldStart] = useState(false);
  const { showSnackbar } = useContext(SnackBarContext);

  useEffect(() => {
    setShouldStart(() => false);
   
    const mic = navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
  
    mic
      .then((stream) => {
        console.log("mic connected!");
        streamObj === null && setStreamObj(stream);

        setShouldStart(true);

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });

        mediaObj === null && setMediaObj(mediaRecorder);
      })
      .catch((err) => {
        console.log(err);
        setRecState("");
        showSnackbar({
          open: true,
          message:
            err.toString().split(": ")[1] ||
            "Error occured when accessing mic!",
          type: "error",
        });
      });
  }, []);

  useEffect(() => {
    function record() {
      if (mediaObj && streamObj) {
        try {
          soundAllowed(
            streamObj,
            micState === "start" || micState === "resume",
            micState === "stop",
            locObj === "light" ? "#BDBDBD" : "#333333"
          );
        } catch (err) {
          // console.log(err);
          // showSnackbar({
          //   open: true,
          //   message: err.toString().split(": ")[2],
          //   type: "error",
          // });
          // if (
          //   err.toString().split(": ")[2] ===
          //   "Connecting AudioNodes from AudioContexts with different sample-rate is currently not supported."
          // )
          //   showSnackbar({
          //     open: true,
          //     message: "Due to problems will firefox wave cannot be visualized.",
          //     type: "info",
          //   });
        }
        if (
          micState === "start" &&
          mediaObj.state === "inactive" &&
          mediaObj.state !== "recording" &&
          mediaObj.state !== "pause"
        )
          mediaObj.start();
        else if (
          micState === "pause" &&
          mediaObj.state !== "inactive" &&
          mediaObj.state !== "paused" &&
          mediaObj.state === "recording"
        )
          mediaObj.pause();
        else if (
          micState === "resume" &&
          mediaObj.state !== "inactive" &&
          mediaObj.state === "paused" &&
          mediaObj.state !== "recording"
        )
          mediaObj.resume();
        else if (micState === "stop" && mediaObj.state !== "inactive") {
          mediaObj.stop();
        }

        mediaObj.addEventListener("dataavailable", (e) => {
          chunks.current.push(e.data);
        });

        mediaObj.addEventListener("stop", (e) => {
          if (chunks.current.length > 0) {
            setFileObj(chunks.current[0]);
            var blob = new Blob(
              chunks.current.length > 0 && [chunks.current[0]],
              {
                type: "audio/webm;codecs=opus",
              }
            );
            if (blob.size > 0) setBlobObj(blob);
            chunks.current = [];
            setStreamObj(null);
            setMediaObj(null);
            setRecState("playing");
          }
        });
      }
    }

    record();

    return () => record();
  }, [micState, mediaObj]);

  useEffect(() => {
    if ((micState === "resume" || micState === "start") && shouldStart) {
      setTimeout(() => {
        if ((micState === "resume" || micState === "start") && shouldStart) {
          setTime(time + 1 / 10);
          setTimeFormatted(convertHMS(time + 1 / 10));
        }
      }, 100);
    }
    if (timeFormatted === timeAllowed) {
      const TA =
        timeAllowed === "00:30"
          ? "30 seconds"
          : `${Number.parseInt(timeAllowed.split(":")[0])} minutes`;
      setMicState("stop");
      setTimeFormatted("00:00");
      showSnackbar({
        open: true,
        message: `Message is limited to ${TA} by project owner!`,
        type: "info",
      });
    }
  }, [time, micState, shouldStart]);

  return (
    <div className="wave-rec">
      <div className="wave-ref--wrapper">
        <canvas id="wave-ref"></canvas>
      </div>
      <div
        className="wc-rec-time"
        style={{
          color: locObj.light === "light" ? "#9b9b9b" : "rgb(51, 51, 51)",
        }}
      >
        {timeFormatted}
      </div>
    </div>
  );
};

export default RecordWave;
