import MicNoneIcon from "@mui/icons-material/MicNone";

// import { ReactComponent as HorizontalWaveIcon } from "../../../icons/svg/horizontal-wave.svg";
import { ReactComponent as PlayGrayIcon } from "../../../icons/svg/play-gray.svg";
import { ReactComponent as PlayDarkIcon } from "../../../icons/svg/play-dark.svg";
import { ReactComponent as StopRedIcon } from "../../../icons/svg/stop-red.svg";
import { ReactComponent as WaveDarkIcon } from "../../../icons/svg/wave-dark.svg";
import { ReactComponent as WaveDarkWaveIcon } from "../../../icons/svg/wave-dark-wave.svg";

import "./style.css";
import { Tooltip } from "@mui/material";

const AddProjectCard = ({
  rec,
  projName,
  desc,
  file,
  showImage,
  shadow,
  bgColor,
  border,
  bg,
  borderColor,
  ProjectBgPlaceholder,
  textColor,
  btnColor,
  btnTextColor,
  light,
  remWat,
}) => {
  return (
    <div
      className={`add-project__bottom-display-top ${
        shadow ? "add-project__bottom-display-top--shadow" : ""
      }`}
      style={{
        backgroundColor: bg ? bgColor : "transparent",
        border: border ? `2px solid ${borderColor}` : "2px solid transparent",
      }}
    >
      <div className="add-project__bottom-display-top__dets">
        {showImage && (
          <div className="add-project__bottom-display-top__dets__img-control">
            <img
              src={(showImage && file) || ProjectBgPlaceholder}
              alt="Project Background Placeholder"
              width="100%"
              height="100%"
              style={{
                borderRadius: 8,
              }}
            />
          </div>
        )}
        <div>
          <Tooltip title={projName}>
            <h3 className="text" style={{ color: textColor }}>
              {projName || "No Name"}
            </h3>
          </Tooltip>

          <p style={{ color: textColor }}>
            {desc ||
              "Leave us a recording with your question and get a chance to be featured on our podcast!"}
          </p>
        </div>
      </div>
      {rec ? (
        <div
          className="add-project__bottom-display-top__audios add-project__bottom-display-top__audios--alter"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              backgroundColor: btnColor,
              color: btnTextColor,
            }}
            type="button"
          >
            <MicNoneIcon style={{ fill: btnTextColor }} />
            Record
          </button>
        </div>
      ) : (
        <div className="add-project__bottom-display-top__audios--icons">
          {light === "light" ? <PlayGrayIcon /> : <PlayDarkIcon />}
          {light === "light" ? <WaveDarkIcon /> : <WaveDarkWaveIcon />}
          <div
            style={{
              color: light === "light" ? "#9b9b9b" : "rgb(51, 51, 51)",
            }}
          >
            4:21
          </div>
          <StopRedIcon />
        </div>
      )}
      {!remWat && (
        <div className="widget-card__watermark">Powered by Voiiice.io</div>
      )}
    </div>
  );
};

export default AddProjectCard;
