import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ProjectBgPlaceholder from "../../icons/svg/project-bg-placeholder.svg";
import { ReactComponent as MicIcon } from "./mic.svg";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import Recorder from "./recorder";
import Player from "./player";
import SubmitForm from "./submit-form";
import { SnackBarContext } from "../../providers/snackbar/context";
import "./widget-card.css";

const WidgetCard = () => {
  const location = useLocation();

  const [loc, setLoc] = useState(location);
  const [locObj, setLocObj] = useState(null);
  const [recState, setRecState] = useState("");
  const [blobObj, setBlobObj] = useState(null);
  const [fileObj, setFileObj] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [locImg, setLocImg] = useState(ProjectBgPlaceholder);
  const { showSnackbar } = useContext(SnackBarContext);

  useEffect(() => {
    deserializeUrl();
    if (location !== loc) {
      setLoc(loc);
    }
  }, [location]);

  useEffect(() => {
    if (locObj && locObj.show_img) {
      setLocImg(locObj.proj_img);
    }
  }, [locObj]);

  const deserializeUrl = () => {
    if (loc.search) {
      let projectUrl = loc.search.split("?")[1].split("=")[1];
      axios
        .get(`project/get/${projectUrl}`)
        // .get(`api/project/get/${projectUrl}`)
        .then(({ data }) => {
          setLocObj(data.data[0]);
          setLoading(false);
          showSnackbar({
            open: false,
            message: "",
            type: "error",
          });
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
          showSnackbar({
            open: true,
            message:
              err.response?.data?.message ||
              err.response?.data?.error ||
              "Failed to fetch widget data!",
            type: "error",
          });
        });
    }
  };

  const IsBgColor = () => {
    if (locObj && locObj.bg === "true" && locObj.bg_color !== "false")
      return true;
    return false;
  };

  const isBorderColor = () => {
    if (locObj && locObj.border === "true" && locObj.border_color !== "false")
      return true;
    return false;
  };

  return (
    <>
      {!loading ? (
        locObj ? (
          <div
            className={`widget-card ${
              locObj
                ? locObj.show_shadow === "true"
                  ? "widget-card--shadow"
                  : ""
                : ""
            }`}
            style={{
              backgroundColor:
                locObj && IsBgColor() ? `${locObj.bg_color}` : "transparent",
              border: `2px solid ${
                isBorderColor() ? locObj.border_color : "transparent"
              }`,
            }}
          >
            {!submit ? (
              <>
                <div className="widget-card__top">
                  {locObj.show_img === "true" && (
                    <div className="widget-card__img-wrapper">
                      <img src={locImg} alt="placeholder" />
                    </div>
                  )}
                  <div
                    className="widget-card__details-wrapper"
                    style={{ width: locObj.show_img === "false" && "100%" }}
                  >
                    <h1
                      style={{
                        color:
                          locObj && locObj.text_color
                            ? `${locObj.text_color}`
                            : "#141332",
                      }}
                    >
                      {locObj ? locObj.name : "Project Name"}
                    </h1>
                    <p
                      style={{
                        color:
                          locObj && locObj.text_color
                            ? `${locObj.text_color}`
                            : "#141332",
                      }}
                    >
                      {locObj ? locObj.desc : "Description goes here!"}
                    </p>
                  </div>
                </div>
                <div className="widget-card__bottom">
                  {recState === "recording" ? (
                    <Recorder
                      setRecState={setRecState}
                      setBlobObj={setBlobObj}
                      setFileObj={setFileObj}
                      locObj={locObj}
                    />
                  ) : recState === "playing" ? (
                    <Player
                      setBlobObj={setBlobObj}
                      blobObj={blobObj}
                      setRecState={setRecState}
                      setSubmit={setSubmit}
                      locObj={locObj}
                    />
                  ) : (
                    <div
                      className="widget-card__record-btn--only"
                      style={{ marginTop: 15 }}
                    >
                      {locObj.proj_status === "live" &&
                      (locObj.user.plan?.plan_type !== "free" ||
                        locObj.user.plan?.plan_type === "vip") ? (
                        <button
                          onClick={() => setRecState("recording")}
                          className="widget-card__record-btn"
                          style={{
                            background: locObj.btn_color
                              ? `${locObj.btn_color}`
                              : "#3864ff",
                            color:
                              locObj && locObj.btn_text_color
                                ? `${locObj.btn_text_color}`
                                : "#ffffff",
                          }}
                        >
                          <div>
                            <MicIcon
                              style={{
                                fill: locObj.btn_text_color
                                  ? `${locObj.btn_text_color}`
                                  : "#ffffff",
                              }}
                            />
                          </div>
                          Record
                        </button>
                      ) : (
                        <div
                          style={{
                            fontSize: 14,
                            color: "#f06868",
                            fontWeight: 500,
                          }}
                        >
                          Sending recordings is currently disabled
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {locObj.remove_watermark === "false" && (
                  <div className="widget-card__watermark">
                    Powered by <span><a target="_blank" href="https://voiiice.io/">Voiiice.io</a></span>
                  </div>
                )}
              </>
            ) : (
              <div className="widget-card__submit-form">
                <SubmitForm
                  fileObj={fileObj}
                  locObj={locObj}
                  setRecState={setRecState}
                  setSubmit={setSubmit}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="widget-card widget-card__no-content widget-card--shadow">
            No content - Please check url!
          </div>
        )
      ) : (
        <div className={`widget-card widget-card--shadow widget-card__loading`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default WidgetCard;
