let obj = {};
let wavePlay = true;
let waveStop = false;
let color = "#BDBDBD";

function init() {
  obj.canvas = document.getElementById("wave-ref");
  obj.ctx = obj.canvas?.getContext("2d");
  // obj.width = window.innerWidth * 0.9;
  obj.width = window.innerWidth * 0.8;
  // obj.height = window.innerHeight * 0.9;
  obj.height = window.innerHeight * 0.8;
  obj.canvas.width = obj.width * window.devicePixelRatio;
  obj.canvas.height = obj.height * window.devicePixelRatio;
  obj.canvas.style.width = obj.width + "px";
  obj.canvas.style.height = obj.height + "px";
  obj.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
}

let timeOffset = 100;
let now = parseInt(performance.now()) / timeOffset;

function loop() {
  if (wavePlay) {
    obj.ctx.clearRect(0, 0, obj.canvas.width, obj.canvas.height);
    obj.analyser.getByteFrequencyData(obj.frequencyArray);
    let max = 0;

    if (parseInt(performance.now() / timeOffset) > now) {
      now = parseInt(performance.now() / timeOffset);
      for (var i = 0; i < obj.frequencyArray.length; i++) {
        if (obj.frequencyArray[i] > max) {
          max = obj.frequencyArray[i];
        }
      }

      // var freq = Math.floor(max * 1.2);
      var freq = Math.floor(max * 1.25);

      obj.bars.push({
        x: obj.width,
        y: obj.height / 2 - freq / 2,
        height: max,
        width: 6,
      });
    }
  }
  !waveStop && draw();
  wavePlay && requestAnimationFrame(loop);
}

obj.bars = [];

function draw() {
  for (let i = 0; i < obj.bars.length; i++) {
    const bar = obj.bars[i];
    obj.ctx.fillStyle = color;
    obj.ctx.fillRect(bar.x, bar.y, bar.width, bar.height);
    // bar.x = bar.x - 1.5;
    bar.x = bar.x - 2;

    if (bar.x < 1) {
      obj.bars.splice(i, 1);
    }
  }
}

export function soundAllowed(stream, play, stop, controls) {
  wavePlay = play;
  waveStop = stop;
  color = controls;
  var AudioContext = window.AudioContext || window.webkitAudioContext;
  // var audioContent = new AudioContext({ sampleRate: 44100 });
  var audioContent = new AudioContext({ sampleRate: 48000 });

  var streamSource = audioContent.createMediaStreamSource(stream);

  obj.analyser = audioContent.createAnalyser();
  streamSource.connect(obj.analyser);
  obj.analyser.fftSize = 4096;
  // obj.analyser.fftSize = 512;
  obj.frequencyArray = new Uint8Array(obj.analyser.frequencyBinCount);
  !waveStop && init();
  wavePlay && loop();
  if (waveStop) {
    obj.bars = [];
  }
}

export function soundNotAllowed() {}
