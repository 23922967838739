import React, { useCallback, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoaderContext } from "./";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex?.drawer + 300,
    color: "#fff",
  },
}));

const LoaderProvider = ({ children }) => {
  const classes = useStyles();
  const [loading, isLoading] = useState(false);

  const showLoader = useCallback((bool) => {
    isLoading(bool);
  });

  return (
    <LoaderContext.Provider value={{ showLoader }}>
      {children}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </LoaderContext.Provider>
  );
};

export { LoaderProvider };
