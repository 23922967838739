import { Fragment } from "react";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Close from "@mui/icons-material/Close";

import RecWave from "../../icons/svg/rec-wave.svg";
import { ReactComponent as SettingsIcon } from "../../icons/svg/setting.svg";
import { RecordContext } from "../../providers/records";
import TabNavsComponent from "../../components/tab-navs/tab-navs";
import AudioStick from "../../components/audio-stick/audio-stick";
import { useNavigate, useParams } from "react-router-dom";

const ShowRecs = ({ values, edit, setBulkDelete, bulkDelete }) => {
  return (
    <Fragment>
      {values.length > 0 ? (
        values.map((record, index) => {
          return (
            <AudioStick
              key={record.id}
              senderName={record.name}
              title={record.title}
              project={record.proj_id.name}
              date={record.date}
              url={record.playUrl}
              index={index + 1}
              liked={record.liked}
              id={record.id}
              playBool={record.playing}
              edit={edit}
              setBulkDelete={setBulkDelete}
              bulkDelete={bulkDelete}
            />
          );
        })
      ) : (
        <div className="all-recs--no-content">No recordings yet</div>
      )}
    </Fragment>
  );
};

const AccountAllRecordings = ({ setTabs, tabs, setSetting, loc }) => {
  const {
    value,
    liked,
    searchRec,
    getProject,
    getProjectName,
    getUserRecordings,
    recLoading,
    deleteBulkRecording,
  } = useContext(RecordContext);
  const navigate = useNavigate();
  const params = useParams();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [edit, setEdit] = useState(false);
  const [bulkDelete, setBulkDelete] = useState([]);
  const { projectRecs, projLiked } = getProject(loc, true);
  const [cookies] = useCookies(["user"]);

  useEffect(() => {
    setEdit(false);
  }, [loc]);

  const checkSearch = (open) => {
    if (open === false) handleSearch("");
    setSearchOpen(open);
  };

  const handleSearch = (search) => {
    setSearchValue(search);
    searchRec(search);
  };

  const deleteSelected = () => {
    deleteBulkRecording(bulkDelete, setBulkDelete, setEdit);
  };

  useEffect(() => {
    if (cookies.token && cookies.user && value.length <= 0)
      getUserRecordings(cookies?.user.id);
  }, []);

  const getCount = () => {
    if (loc !== "all-recordings") {
      if (tabs === "heart" && projLiked && projLiked.length) {
        return projLiked.length;
      } else if (tabs !== "heart" && projectRecs && projectRecs.length) {
        return projectRecs.length;
      } else {
        return 0;
      }
    } else if (loc === "all-recordings") {
      if (tabs === "heart" && liked && liked.length) {
        return liked.length;
      } else if (tabs !== "heart" && value && value.length) {
        return value.length;
      } else {
        return 0;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="all-recs">
        <div className="all-recs__head">
          <div>
            <h3
              className={`${
                loc !== "all-recordings" ? "all-recs__heading" : ""
              }`}
            >
              {loc === "all-recordings" ? "Recordings" : getProjectName(loc)}
            </h3>
            <img src={RecWave} alt="Recording Waves" />
            <div>{`${getCount()}/${
              cookies?.user?.plan?.max_num_recordings || 0
            }`}</div>
            {loc !== "all-recordings" && (
              <SettingsIcon
                onClick={() => {
                  setSetting(true);
                  navigate(`/project/${params.projectid}/setting`);
                }}
                style={{ marginLeft: 20, marginRight: 20, cursor: "pointer" }}
              />
            )}
          </div>
          <div>
            {searchOpen ? (
              <div className="all-recs__search">
                <SearchOutlinedIcon />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  value={searchValue}
                  autoFocus
                />
                <Close onClick={() => checkSearch(false)} />
              </div>
            ) : (
              <SearchOutlinedIcon
                onClick={() => checkSearch(true)}
                style={{ cursor: "pointer" }}
              />
            )}
            {!searchOpen ? (
              <>
                <div>
                  {!edit ? (
                    <button
                      onClick={() => {
                        getCount() > 0 && setEdit(true);
                      }}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        bulkDelete.length > 0
                          ? deleteSelected()
                          : setEdit(false)
                      }
                    >
                      {bulkDelete.length > 0
                        ? "Delete Selected"
                        : "Cancel Deletion"}
                    </button>
                  )}
                </div>
                <div>
                  <TabNavsComponent setTabs={setTabs} tabs={tabs} type="all" />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!recLoading ? (
          <div className="all-recs__content">
            {loc !== "all-recordings" ? (
              <>
                {tabs === "heart" ? (
                  <ShowRecs
                    values={projLiked}
                    edit={edit}
                    setBulkDelete={setBulkDelete}
                    bulkDelete={bulkDelete}
                  />
                ) : (
                  <ShowRecs
                    values={projectRecs}
                    edit={edit}
                    setBulkDelete={setBulkDelete}
                    bulkDelete={bulkDelete}
                  />
                )}
              </>
            ) : (
              <>
                {tabs === "heart" ? (
                  <ShowRecs
                    values={liked}
                    edit={edit}
                    setBulkDelete={setBulkDelete}
                    bulkDelete={bulkDelete}
                  />
                ) : (
                  <ShowRecs
                    values={value}
                    edit={edit}
                    setBulkDelete={setBulkDelete}
                    bulkDelete={bulkDelete}
                  />
                )}
              </>
            )}
          </div>
        ) : (
          <div
            className="all-recs__content"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
};

export default AccountAllRecordings;
