import WidgetCard from "../components/widget-card/widget-card";

import "../styles/widget.css";

const WidgetPage = () => {
  return (
    <div className="widget">
      <WidgetCard />
    </div>
  );
};

export default WidgetPage;
