import { Routes, Route } from "react-router-dom";
import NotFoundComponent from "./pages/404";
import AccountPage from "./pages/account/index";
import AuthPage from "./pages/auth";
import FaqComponent from "./pages/faq";
import HomePage from "./pages/home";
import PricingPage from "./pages/pricing";
import PageLayout from "./components/page-layout/page-layout";
import WidgetPage from "./pages/widget";
import ForgotPassword from "./pages/forgot-password";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/widget" element={<WidgetPage />} />
      <Route element={<PageLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/auth/:authId" element={<AuthPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/faq" element={<FaqComponent />} />
        <Route path="/pricing" element={<PricingPage />} />
        {/* <Route path="/account" element={<AccountPage />} /> */}
        <Route path="/account/:projectid" element={<AccountPage />} />
        <Route path="/project" element={<AccountPage />} />
        <Route path="/project/:projectid" element={<AccountPage />} />
        <Route path="/project/:projectid/setting" element={<AccountPage />} />
        <Route path="/project/add-project" element={<AccountPage />} />
        <Route path="*" element={<NotFoundComponent />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
