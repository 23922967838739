import { useEffect } from "react";
import { useState } from "react";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export default useWindowWidth;
