import { Link } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";

import "./footer.css";

const FooterComponent = () => {
  return (
    <footer className="footer">
      <hr />
      <div className="footer-actions">
        <div className="footer-actions--link">
          <a
            href="https://frstnntiw.gleap.help/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQs
          </a>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
        <div className="footer-actions--icon">
          <a
            href="https://twitter.com/Voiiice_io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
