import { CloseSharp } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import { createSubscription } from "../../utils/createSession";
import "./popup.css";

const Popup = ({ tabs, plan, pricing, setOpenPopup }) => {
  const [cookie] = useCookies(["token", "user"]);

  const handelClick = async (plan, priceId, planType, token) => {
    setOpenPopup(false);
    await createSubscription(priceId, plan, planType, token);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-content--header">
          <div className="popup-content--header-content">
            Please Select Subscription
          </div>
          <div
            className="popup-content--close"
            onClick={() => setOpenPopup(false)}
          >
            <CloseSharp />
          </div>
        </div>
        <hr />
        <div className="popup-content--details">
          {
            <>
              {plan === "premium" ? (
                <p className="popup-text">
                  Warnning! you are downgrading, so you will be unsubscribed
                  from Premium.
                </p>
              ) : plan === "pro" && tabs === "premium" ? (
                <p className="popup-text">
                  You are Upgrading, so you will be unsubscribed from Pro.
                </p>
              ) : plan === "pro" && tabs === "starter" ? (
                <p className="popup-text">
                  Warnning! you are downgrading, so you will be unsubscribed
                  from Pro.
                </p>
              ) : plan === "starter" ? (
                <p className="popup-text">
                  You are Upgrading, so you will be unsubscribed from starter.
                </p>
              ) : (
                <p className="popup-text">Subscribe to Upgrade.</p>
              )}
              <div className="popup-btns">
                <button
                  className="popup-btn"
                  onClick={() =>
                    handelClick(tabs, pricing?.mon_priceId, "mo", cookie?.token)
                  }
                >
                  Monthly - ${pricing.mon}
                </button>
                <button
                  className="popup-btn"
                  onClick={() =>
                    handelClick(tabs, pricing?.anu_priceId, "yr", cookie?.token)
                  }
                >
                  Annually - ${pricing.anu}
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default Popup;
