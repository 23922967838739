import AppRoutes from "./routes";
import { useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import Gleap from "gleap";
import configAxios from "./plugins/axios.config";
import { RecordContext } from "./providers/records";
import "./App.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
configAxios();

function App() {
  const { getUserProjects, getUserRecordings } = useContext(RecordContext);
  const [cookies, setCookie] = useCookies(["user", "token"]);
  const location = useLocation();
  useEffect(() => {
    location.pathname === "/widget" ? (
      <></>
    ) : (
      Gleap.initialize(process.env.REACT_APP_GLEAP_SECRET_KEY)
    );
    if (cookies.token && cookies.user) {
      getUserProjects();
      getUserRecordings(cookies.user.id);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("auth", {
          headers: {
            token: cookies?.token,
          },
        });
        const user = response.data;
        setCookie("user", user, { path: "/" });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();

  }, []);
  return <AppRoutes />;
}

export default App;
