import { Outlet } from "react-router-dom";

import FooterComponent from "../footer/footer";
import HeaderComponent from "../header/header";

const PageLayout = () => {
  return (
    <div className="app">
      <div className="app-circle-1" />
      <div className="app-circle-2" />
      <div className="app-content__alignment">
        <div className="app-content__width-adjust">
          <HeaderComponent />
          <div className="app-page-wrapper">
            <Outlet />
          </div>
          <FooterComponent />
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
