import "./icon-button.css";

const IconButtonComponent = (props) => {
  const {
    text,
    onClick = () => {},
    Icon,
    style = {},
    className = "",
    ...newProps
  } = props;
  return (
    <button
      className={`icon-button ${className}`}
      onClick={onClick}
      style={style}
      {...newProps}
    >
      <img src={Icon} alt={text} />
      <span>{text}</span>
    </button>
  );
};

export default IconButtonComponent;
