import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ButtonComponent from "../button/button";

import "./pricing-cards.css";

const PricingCards = ({
  title,
  li,
  pricing,
  btn,
  inverted,
  disabled,
  onClick = () => {},
}) => {

  return (
    <div className={`pricing-card ${inverted ? "pricing-card--inverted" : ""}`}>
      <div className="pricing-card__title">{title}</div>
      {pricing && (
        <div className="pricing-card__pricing">
          <span className="pricing-card__pricing-dollar">$</span>
          <span>{pricing.p}</span>
          <span style={{ fontSize: 15.5 }}>/{pricing.dur}</span>
        </div>
      )}
      {li.map((l) => (
        <div key={l.title + l.small} className="pricing-card__list">
          <div className="pricing-card__list-title">{l.title}</div>
          {!l.img ? (
            <div className="pricing-card__list-small">{l.small}</div>
          ) : (
            <CheckCircleIcon
              className="pricing-card__list-img"
              style={{ height: 30, width: 30 }}
            />
          )}
        </div>
      ))}
      {btn && (
        <div
          className={`pricing-card__list-btn ${
            inverted ? "pricing-card__list-btn--inverted" : ""
          }`}
        >
          <ButtonComponent
            disabled={disabled}
            type="filled"
            text="SELECT"
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default PricingCards;
