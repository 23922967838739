import { useEffect } from "react";
import AccordianBlock from "../components/accordian-block/accordian-block";

import "../styles/faq.css";

const FaqComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="faq">
      <div className="faq-head">FAQs</div>
      <div className="faq-list">
        <AccordianBlock />
      </div>
    </div>
  );
};

export default FaqComponent;
