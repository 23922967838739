import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Drawer } from "@mui/material";

import AccountSidebar from "../../components/account-sidebar/account-sidebar";
import AccountUser from "./account-user";
import Project from "./project";
import AllSettings from "./all-settings";
import AddProject from "./add-project";
import useWindowWidth from "../../hooks/use-width";
import { ReactComponent as MenuPlayIcon } from "../../icons/svg/menu-play.svg";

import "../../styles/account.css";
import { useCookies } from "react-cookie";

const AccountPage = () => {
  const [loc, setLoc] = useState("");
  const [locStart, setLocStart] = useState("");
  const [open, setOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const location = useLocation();
  const width = useWindowWidth();
  const navigate = useNavigate();
  const [cookie] = useCookies(["user", "token"]);

  const NavList = () => (
    <Box
      sx={{ width: 310 }}
      role="presentation"
      onKeyDown={() => setOpen(false)}
    >
      <AccountSidebar loc={loc} variant="small" setOpen={setOpen} />
    </Box>
  );

  useEffect(() => {
    if (!cookie?.token && !cookie?.user) navigate("/auth/login");
    else if (cookie?.token && cookie?.user) {
      setAuthLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname.split("/")[2] !== loc ||
      location.pathname.split("/")[1] !== locStart
    ) {
      setLoc(location.pathname.split("/")[2]);
      setLocStart(location.pathname.split("/")[1]);
    }
    setPageLoading(false);
  }, [location.search, location.pathname]);

  return (
    <div className="account">
      {width < 1130 ? (
        <>
          <MenuPlayIcon
            className="sidebar-small__btn"
            onClick={() => setOpen(true)}
          />
          <div className="sidebar-small">
            <Drawer
              anchor={"left"}
              open={open}
              onClose={() => setOpen(false)}
              className="account-small-drawer"
            >
              <NavList />
            </Drawer>
          </div>
        </>
      ) : (
        <AccountSidebar loc={loc} />
      )}
      <div className="account-right" style={{ minHeight: 670 }}>
        {pageLoading || authLoading ? (
          <div className="account-right__loading">
            <CircularProgress />
            &nbsp;&nbsp;&nbsp;<h5>Loading...</h5>
          </div>
        ) : (
          <>
            {locStart === "project" ? (
              loc === "add-project" ? (
                <AddProject />
              ) : (
                <Project />
              )
            ) : (
              <>
                {locStart === "account" &&
                  (loc === "users" ? (
                    <AccountUser />
                  ) : loc === "settings" ? (
                    <AllSettings />
                  ) : (
                    <AllSettings />
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AccountPage;
