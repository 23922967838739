import { useEffect, useRef, useState } from "react";
import ReactGPicker from "react-gcolor-picker";

import "./style.css";

const ColorPicker = ({ handleBgChange, value }) => {
  const node = useRef();
  const [show, setShow] = useState(false);

  const clickOutside = (e) => {
    if (!node.current.contains(e.target)) setShow(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [show]);

  const checkBg = (e) => {
    handleBgChange(e);
  };

  return (
    <div className="cp" ref={node}>
      <div onClick={() => setShow(!show)} style={{ backgroundColor: value }} />
      <div className={`${show ? "" : "cp--hide"}`}>
        <ReactGPicker value={value} onChange={checkBg} debounceMS={1} />
        <div className="cp-color" style={{ backgroundColor: value }} />
      </div>
    </div>
  );
};

export default ColorPicker;
