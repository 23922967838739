import { useRef, useContext, useEffect, useState } from "react";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Switch } from "@mui/material";
import RecordContext from "../../../providers/records/record-context";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { ghcolors } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import ColorPicker from "./color-picker";
import ButtonComponent from "../../../components/button/button";
import TabNavsComponent from "../../../components/tab-navs/tab-navs";
import ProjectBgPlaceholder from "../../../icons/svg/project-bg-placeholder.svg";
import { ReactComponent as InfoIcon } from "../../../icons/svg/info-icon.svg";
import { ReactComponent as CopyIcon } from "../../../icons/svg/copy.svg";
import AddProjectCard from "./add-project-card";
import { SnackBarContext } from "../../../providers/snackbar/context";
import { useBlocker } from "../../../utils/useBlocker";

import "./style.css";
import { checkTrial } from "../../../utils/createSession";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#393865",
    borderRadius: 12,
    padding: "8px 14px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    color: "#FFFFFF",
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 32,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(31px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#3864FF" : "#3864FF",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#3864FF",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    transform: "translateY(1.4px)",
    boxSizing: "border-box",
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 100,
    backgroundColor: theme.palette.mode === "light" ? "#EAEAEA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddProject = ({ update = false, projectSettings, setSetting, loc }) => {
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies(["user", "token"]);
  const [projName, setProjName] = useState("");
  const [desc, setDesc] = useState("");
  const [min, setMin] = useState("30 sec");
  const [on, setOn] = useState("live");
  const [nameReq, setNameReq] = useState(true);
  const [emailReq, setEmailReq] = useState(false);
  const [remWat, setRemWat] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [light, setLight] = useState("light");
  const [borderColor, setBorderColor] = useState("rgb(255, 255, 255)");
  const [bgColor, setBgColor] = useState("rgb(255, 255, 255)");
  const [textColor, setTextColor] = useState("rgb(20, 19, 50)");
  const [btnColor, setBtnColor] = useState("rgb(56, 100, 255)");
  const [btnTextColor, setBtnTextColor] = useState("rgb(255, 255, 255)");
  const [border, setBorder] = useState(false);
  const [bg, setBg] = useState(false);
  const [shadow, setShadow] = useState(true);
  const [showImage, setShowImage] = useState(update ? true : false);
  const [filename, setFilename] = useState(null);
  const [file, setFile] = useState(null);
  const [fullFile, setFullFile] = useState(null);
  const [embed, setEmbed] = useState(
    "Please Publish Project to Enable the Widget."
  );
  const ref = useRef(null);
  const inputFile = useRef(null);
  const {
    getProjectNamesAndIds,
    idExist,
    saveOrUpdateProjects,
    deleteProject,
    responseLoading,
  } = useContext(RecordContext);
  const { showSnackbar } = useContext(SnackBarContext);
  const location = useLocation();
  const [dirty, setDirty] = useState(false);
  const [isEvent, setIsEvent] = useState(null);

  const schema = yup
    .object({
      proj_name: yup
        .string()
        .min(4, "Project Name should contain at least 4 characters!")
        .max(40, "Project Name should contain at most 40 characters!")
        .required("Project Name is required!"),
      desc: yup
        .string()
        .min(10, "Project Description should contain at least 10 characters!")
        .max(50, "Project Description should contain at most 50 characters!")
        .required("Project Description is required!"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  let projname = watch("proj_name");
  let description = watch("desc");

  useEffect(() => {
    setProjName(projname);
    setDesc(description);
  }, [projname, description]);

  useEffect(() => {
    if (isDirty !== dirty) setDirty(isDirty);
    if (isEvent !== null && !dirty) navigate(isEvent);
  }, [isDirty, isEvent]);

  const handleNavigation = async (e) => {
    const res = window.confirm(
      "You have unsaved changes, do you want to continue?"
    );
    if (res) {
      setDirty(false);
      setIsEvent(e.location.pathname);
    }
  };

  useEffect(() => {
    if (
      cookies?.user.plan.plan_type === "pro" ||
      cookies?.user.plan.plan_type === "premium" ||
      cookies?.user.plan.plan_type === "vip"
    ) {
      setDisabled(false);
    }
  }, []);

  useBlocker(handleNavigation, dirty);

  useEffect(() => {
    if (!responseLoading) {
      if (location.hash !== "") {
        try {
          const toScroll = document.getElementById(location.hash.split("#")[1]);
          toScroll.scrollIntoView({ behavior: "smooth", block: "nearest" });
        } catch (err) {}
      }
    }
  }, []);

  useEffect(() => {
    if (update && projectSettings) {
      setProjName(projectSettings?.name);
      setDesc(projectSettings?.desc);
      setMin(projectSettings?.msg_max_len);
      setOn(projectSettings?.proj_status);
      setNameReq(projectSettings?.name_req === "true");
      setEmailReq(projectSettings?.email_req === "true");
      setRemWat(projectSettings?.remove_watermark === "true");
      setShowImage(projectSettings?.show_img === "true");
      setBorder(projectSettings?.border === "true");
      setBorderColor(projectSettings?.border_color);
      setBg(projectSettings?.bg === "true");
      setBgColor(projectSettings?.bg_color);
      setTextColor(projectSettings?.text_color);
      setBtnColor(projectSettings?.btn_color);
      setBtnTextColor(projectSettings?.btn_text_color);
      setShadow(projectSettings?.show_shadow === "true");
      setLight(projectSettings?.controls);
      setEmbed(
        `<iframe
    src="${window.location.protocol}//${window.location.hostname}:${window.location.port}/widget?project=${projectSettings.id}"
    style="min-height: 300px; border: none; height: 100%; width: 100%"
    allow="camera; microphone"
    ></iframe>`
      );
      setFile(projectSettings.proj_img);
      reset({ proj_name: projectSettings.name, desc: projectSettings.desc });
    }
  }, [loc, projectSettings]);

  const onFileOpen = () => {
    inputFile.current.click();
  };

  const handleBack = () => {
    if (update) {
      navigate(`/project/${loc}`);
      setSetting(false);
    }
  };

  const validateFileType = (fileType) => {
    if (
      !(
        fileType.toLowerCase() === "png" ||
        fileType.toLowerCase() === "jpg" ||
        fileType.toLowerCase() === "jpeg"
      )
    )
      return true;
    return false;
  };

  const onFileUpload = () => {
    try {
      if (inputFile?.current?.files.length > 0) {
        const fileType = inputFile?.current?.files[0].type.split("/")[1];
        const fileSize = inputFile?.current?.files[0].size / (1024 * 1024);
        if (fileSize > 5) throw Error("Max 5mb allowed for a file.");
        else if (validateFileType(fileType))
          throw Error("Only png, jpg, jpeg are allowed!");
        setShowImage(true);
        const file = inputFile?.current?.files[0];
        setFullFile(file);
        setFilename(file.name);
        setFile(URL.createObjectURL(file));
      }
    } catch (err) {
      showSnackbar({
        open: true,
        message: err.toString() || "File Upload Failed!",
        type: "error",
      });
    }
  };

  const SaveOrUpdateData = () => {
    if (Object.keys(errors).length > 0) return;
    if (projName.length < 4) return;
    let name = projName.toLowerCase().split(" ").join("_");
    if (update && name !== projectSettings.url && idExist(name)) {
      return;
    }
    if (!update && idExist(name)) {
      showSnackbar({
        open: true,
        message: "Project with this name already exist!",
        type: "error",
      });
      return;
    }
    const data = {
      url: name,
      name: projName,
      desc: desc,
      msg_max_len: min,
      proj_status: on,
      name_req: nameReq,
      email_req: emailReq,
      remove_watermark: remWat,
      show_img: showImage,
      border: border,
      border_color: borderColor,
      bg: bg,
      bg_color: bgColor,
      text_color: textColor,
      btn_color: btnColor,
      btn_text_color: btnTextColor,
      show_shadow: shadow,
      controls: light,
      full_file: fullFile,
    };
    setDirty(false);
    saveOrUpdateProjects(data, update, projectSettings?.id || null, true);
  };

  const handelCheck = async () => {
    if (
      cookies?.user.plan.plan_type === "free" &&
      cookies?.user.trial_status === false
    ) {
      showSnackbar({
        open: true,
        message: "Subscribe Or Start a Free Trial Now!",
        type: "info",
      });

      navigate("/pricing?trial=true");
    } else if (
      cookies?.user.plan.plan_type === "free" &&
      cookies?.user.trial_status === true
    ) {
      showSnackbar({
        open: true,
        message: "Subscribe to a plan to use our services",
        type: "warning",
      });
      navigate("/pricing");
    } else if (cookies?.user.plan.plan_type === "trial") {
      const check = await checkTrial(cookies?.token);

      if (check.trial) {
        update
          ? SaveOrUpdateData(projectSettings.id)
          : getProjectNamesAndIds().length /
              cookies?.user?.plan.max_num_podcasts <
            1
          ? SaveOrUpdateData(null)
          : showSnackbar({
              open: true,
              message: "Upgrade subscription to create more Projects",
              type: "error",
            });
      } else if (!check.trial) {
        const expires = new Date();
        expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
        setCookies("user", check?.user, {
          path: "/",
          expires: expires,
        });

        showSnackbar({
          open: true,
          message: "Your Free Trial is Expiered Now!",
          type: "error",
        });

        navigate("/pricing");
      }
    } else {
      update
        ? SaveOrUpdateData(projectSettings.id)
        : getProjectNamesAndIds().length /
            cookies?.user?.plan.max_num_podcasts <
          1
        ? SaveOrUpdateData(null)
        : showSnackbar({
            open: true,
            message: "Upgrade subscription to Edit or create more Projects",
            type: "error",
          });
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(embed);
    showSnackbar({
      open: true,
      message: "Copied!",
      type: "info",
    });
  };

  useEffect(() => {
    if (!file && !update) {
      setShowImage(false);
    }
  }, [file, showImage]);

  return (
    <div  className="add-project" >
      <div
        className={`add-project__top ${
          update ? "add-project__top--alter" : ""
        }`}
      >
        <div className="add-project__top-actions">
          <div style={{ display: !update ? "none" : "flex" }}>
            <ArrowBackSharpIcon onClick={handleBack} />
          </div>
          <h2>{!update ? "Add a Project" : "Settings"}</h2>
          <Tooltip title={projName}>
            <span className="text">{projname}</span>
          </Tooltip>
        </div>
        <div>
          <ButtonComponent
            text={
              update
                ? responseLoading
                  ? "Saving..."
                  : "Save"
                : responseLoading
                ? "Publishing..."
                : "Publish"
            }
            submit={false}
            onClick={handleSubmit(handelCheck)}
            disabled={responseLoading}
            type="filled"
            style={{
              background:
                "linear-gradient(109.46deg, #6B8CFF 17.71%, #3D38FF 64.06%)",
              boxShadow: "0px 4px 12px rgba(56, 100, 255, 0.16)",
              borderRadius: "8px",
            }}
          />
        </div>
      </div>
      <div className="add-project__middle">
        <div className="add-project__middle-dets">
          <div className="add-project__middle-inps">
            <input
              type="text"
              placeholder="Project Name"
              {...register("proj_name")}
              aria-invalid={errors.proj_name ? "true" : "false"}
            />
            {errors.proj_name && (
              <p
                role="alert"
                className="auth-tab__error auth-tab__error--alter"
              >
                {errors.proj_name?.message}
              </p>
            )}
            <textarea
              placeholder="Description"
              rows={5}
              {...register("desc")}
              aria-invalid={errors.desc ? "true" : "false"}
            ></textarea>
            {errors.desc && (
              <p role="alert" className="auth-tab__error">
                {errors.desc?.message}
              </p>
            )}
          </div>
          <div className="add-project__middle-img">
            <p>Project Image:</p>
            <div className="add-project__middle-img__bg">
              <div className="add-project__middle-img__bg-wrapper">
                <img
                  src={file || ProjectBgPlaceholder}
                  alt="Project Background Placeholder"
                  className={`${
                    file ? "add-project__middle-img__bg-file" : ""
                  }`}
                />
              </div>
              <div className="add-project__middle-img__bg-up">
                <div>
                  <small>{filename || "No Image Selected"}</small>
                </div>
                <div>
                  <ButtonComponent
                    text="Upload"
                    type="outlined"
                    style={{
                      border: "1px solid #9B9B9B",
                      color: "#828282",
                      backgroundColor: "transparent",
                      filter: "none",
                    }}
                    onClick={onFileOpen}
                  />
                </div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={onFileUpload}
                />
                <small style={{ marginTop: 16 }}>Max 5mb upload size</small>
              </div>
            </div>
          </div>
        </div>
        <div className="add-project__middle-opts">
          <div className="add-project__middle-opt">
            <small>Message max length:</small>
            <TabNavsComponent type="min" tabs={min} setTabs={setMin} />
          </div>
          <div className="add-project__middle-opt">
            <small>Project status:</small>
            <TabNavsComponent type="live" tabs={on} setTabs={setOn} />
            <HtmlTooltip
              title={cookies?.user.plan.plan_type === "free" &&
              cookies?.user.trial_status === true ?"Subscribe to a Plan to Use our Services":"Temporarily disable the widget to stop receiving messages"}
              arrow
              className="add-project__middle-opt__tooltip"
            >
              <InfoIcon />
            </HtmlTooltip>
          </div>




          <div className="add-project__middle-opt">
            <small>Name required:</small>
            <IOSSwitch checked={nameReq} onClick={() => setNameReq(!nameReq)} />
          </div>
          <div className="add-project__middle-opt">
            <small>Email required:</small>
            <IOSSwitch
              checked={emailReq}
              onClick={() => setEmailReq(!emailReq)}
            />
          </div>
          <div className="add-project__middle-opt">
            <small>Remove “Powered by Voiiice.io”:</small>
            <IOSSwitch
              checked={remWat}
              onClick={() => {
                disabled ? setRemWat(false) : setRemWat(!remWat);
              }}
            />
            {disabled ? (
              <HtmlTooltip
                title="Pro and Premium accounts only"
                arrow
                className="add-project__middle-opt__tooltip"
              >
                <InfoIcon />
              </HtmlTooltip>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="add-project__separator">
        <hr />
        <small>Get Embeddable code for your website</small>
      </div>
      <div className="add-project__bottom" id="embeded-code">
        <div className="add-project__bottom-selections">
          <div className="add-project__bottom-selections__embed">
            <p>Embeddable code</p>
            <div>
              <div ref={ref}>
                <SyntaxHighlighter
                  language="jsx"
                  style={ghcolors}
                  wrapLongLines={true}
                  wrapLines={true}
                >
                  {embed}
                </SyntaxHighlighter>
              </div>
              {update && (
                <p>
                  <CopyIcon onClick={() => copyText()} />
                </p>
              )}
            </div>
          </div>
          <div className="add-project__bottom-selections__opts">
            <p>Design your widget</p>
            <div>
              <div className="add-project__middle-opt">
                <small>Show image:</small>
                <IOSSwitch
                  checked={showImage}
                  onClick={() => file && setShowImage(!showImage)}
                />
              </div>
              <div className="add-project__middle-opt">
                <small>Border color:</small>
                <IOSSwitch
                  checked={border}
                  onClick={() => {
                    disabled ? setBorder(false) : setBorder(!border);
                  }}
                />
                {border && (
                  <ColorPicker
                    handleBgChange={setBorderColor}
                    value={borderColor}
                  />
                )}
                {disabled ? (
                  <HtmlTooltip
                    title="Pro and Premium accounts only"
                    arrow
                    className="add-project__middle-opt__tooltip"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                ) : (
                  <></>
                )}
              </div>
              <div className="add-project__middle-opt">
                <small>Background color:</small>
                <IOSSwitch
                  checked={bg}
                  onClick={() => {
                    disabled ? setBg(false) : setBg(!bg);
                  }}
                />
                {bg && (
                  <ColorPicker handleBgChange={setBgColor} value={bgColor} />
                )}
                {disabled ? (
                  <HtmlTooltip
                    title="Pro and Premium accounts only"
                    arrow
                    className="add-project__middle-opt__tooltip"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                ) : (
                  <></>
                )}
              </div>
              <div className="add-project__middle-opt">
                <small>Text color:</small>
                {disabled ? (
                  <HtmlTooltip
                    title="Pro and Premium accounts only"
                    arrow
                    className="add-project__middle-opt__tooltip"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                ) : (
                  <ColorPicker
                    handleBgChange={setTextColor}
                    value={textColor}
                  />
                )}
              </div>
              <div className="add-project__middle-opt">
                <small>Button color:</small>
                {disabled ? (
                  <HtmlTooltip
                    title="Pro and Premium accounts only"
                    arrow
                    className="add-project__middle-opt__tooltip"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                ) : (
                  <ColorPicker handleBgChange={setBtnColor} value={btnColor} />
                )}
              </div>
              <div className="add-project__middle-opt">
                <small>Button Text color:</small>
                {disabled ? (
                  <HtmlTooltip
                    title="Pro and Premium accounts only"
                    arrow
                    className="add-project__middle-opt__tooltip"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                ) : (
                  <ColorPicker
                    handleBgChange={setBtnTextColor}
                    value={btnTextColor}
                  />
                )}
              </div>
              <div className="add-project__middle-opt">
                <small>Show shadow:</small>
                <IOSSwitch
                  checked={shadow}
                  onClick={() => {
                    disabled ? setShadow(true) : setShadow(!shadow);
                  }}
                />
                {disabled ? (
                  <HtmlTooltip
                    title="Pro and Premium accounts only"
                    arrow
                    className="add-project__middle-opt__tooltip"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                ) : (
                  <></>
                )}
              </div>
              <div className="add-project__middle-opt">
                <small>Controls:</small>
                <TabNavsComponent
                  setTabs={setLight}
                  tabs={light}
                  type="light"
                />
                {disabled ? (
                  <HtmlTooltip
                    title="Pro and Premium accounts only"
                    arrow
                    className="add-project__middle-opt__tooltip"
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-project__bottom-display">
          <h2>Widget demonstration</h2>
          <AddProjectCard
            rec={true}
            projName={projName}
            desc={desc}
            file={file}
            showImage={showImage}
            shadow={shadow}
            bgColor={bgColor}
            border={border}
            bg={bg}
            borderColor={borderColor}
            ProjectBgPlaceholder={ProjectBgPlaceholder}
            textColor={textColor}
            btnColor={btnColor}
            btnTextColor={btnTextColor}
            light={light}
            remWat={remWat}
          />
          <div style={{ marginBottom: 34 }} />
          <AddProjectCard
            rec={false}
            projName={projName}
            desc={desc}
            file={file}
            showImage={showImage}
            shadow={shadow}
            bgColor={bgColor}
            border={border}
            bg={bg}
            borderColor={borderColor}
            ProjectBgPlaceholder={ProjectBgPlaceholder}
            textColor={textColor}
            btnColor={btnColor}
            btnTextColor={btnTextColor}
            light={light}
            remWat={remWat}
          />
        </div>
      </div>
      {update && (
        <div className="add-project__delete">
          <hr />
          <div>
            <ButtonComponent
              text="Delete Project"
              type="outlined"
              onClick={() => deleteProject(projectSettings.id, setSetting)}
            />
            <div>
              <small>This cannot be undone.</small>
              <small>
                All recordings and project settings will be deleted.
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProject;
