import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./accordian-stick.css";

const AccordianStick = ({ expanded, handleChange, title, desc, id }) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="accordian-stick"
    >
      <AccordionSummary
        className="accordian-stick__summary"
        id={id}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className="accordian-stick__description">
        <Typography>{desc}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordianStick;
