import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SidebarCardComponent from "../../components/sidebar-card/sidebar-card";
import { RecordContext } from "../../providers/records";
import { SnackBarContext } from "../../providers/snackbar";
import "./account-sidebar.css";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { checkTrial } from "../../utils/createSession";
import { Tooltip } from "@mui/material";

const AccountSidebar = ({ variant, setOpen = () => {} }) => {
  const { showSnackbar } = useContext(SnackBarContext);
  const [cookies, setCookies] = useCookies(["token", "user"]);
  const [loc, setLoc] = useState("");
  const [plan, setPlan] = useState("");
  const [projectOpen, setProjectOpen] = useState(true);
  const navigate = useNavigate();
  const { getProjectNamesAndIds, currentPlaying, stopAll } =
    useContext(RecordContext);
  const location = useLocation();

  useEffect(() => {
    setLoc(location.pathname.split("/")[2]);
  }, [location.pathname]);

  useEffect(() => {
    setPlan(cookies?.user.plan.plan_type);
  }, [cookies]);

  const handelCheck = async () => {
    if (plan === "free" && cookies?.user.trial_status === false) {
      showSnackbar({
        open: true,
        message: "Subscribe Or Start a Free Trial Now!",
        type: "info",
      });

      navigate("/pricing?trial=true");
    } else if (plan === "free" && cookies?.user.trial_status === true) {
      showSnackbar({
        open: true,
        message: "Subscribe to a plan to use our services",
        type: "warning",
      });
      navigate("/pricing?trial=true");
    } else if (plan === "trial") {
      const check = await checkTrial(cookies?.token);

      if (check.trial) {
        navigate("/project/add-project");
      } else if (!check.trial) {
        const expires = new Date();
        expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
        setCookies("user", check?.user, {
          path: "/",
          expires: expires,
        });

        showSnackbar({
          open: true,
          message: "Your Free Trial is Expiered Now!",
          type: "error",
        });

        navigate("/pricing");
      }
    } else {
      navigate("/project/add-project");
    }
  };

  return (
    <>
      {variant === "small" ? (
        <div className="account-left--small">
          <div className="account-left__all-recordings">
            <div
              className={`${
                loc === "all-recordings" ? "account-left--active" : ""
              }`}
            >
              <button
                onClick={() => {
                  navigate("/project/all-recordings");
                  setOpen(false);
                }}
              >
                All Recordings
              </button>
            </div>
          </div>
          <div className="account-left__projects">
            <div>
              <button onClick={() => setProjectOpen(!projectOpen)}>
                <span>
                  Projects
                  {cookies?.user?.plan.plan_type === "free" ||
                  cookies?.user?.plan.plan_type === "premium" ||
                  cookies?.user?.plan.plan_type === "vip"
                    ? ` ${getProjectNamesAndIds().length}`
                    : ` ${getProjectNamesAndIds().length}/${
                        cookies?.user?.plan.max_num_podcasts
                      }`}
                </span>
                {projectOpen ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}
              </button>
            </div>
            <div
              className={`${
                !projectOpen ? "account-left__projects-list--hide" : ""
              } account-left__projects-list`}
            >
              {getProjectNamesAndIds().map((pnu) => {
                return (
                  <div
                    key={pnu.id}
                    onClick={() => {
                      navigate(`/project/${pnu.id}`);
                      stopAll();
                      setOpen(false);
                    }}
                    className={`${
                      loc === pnu.id ? "account-left-div--active" : ""
                    } account-left__projects-list__items`}
                  >
                    <div>{pnu.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="account-left__add">
            <div
              className={`${
                loc === "add-project" ? "account-left--active" : ""
              }`}
            >
              <Tooltip
                title={
                  getProjectNamesAndIds().length /
                    cookies?.user?.plan.max_num_podcasts >=
                  1
                    ? "Upgrade to create more projects"
                    : ""
                }
              >
                <button
                  disabled={
                    getProjectNamesAndIds().length /
                      cookies?.user?.plan.max_num_podcasts >=
                    1
                      ? true
                      : false
                  }
                  onClick={() => handelCheck()}
                >
                  <AddCircleIcon />
                  <span>Add a Project </span>
                </button>
              </Tooltip>
            </div>
          </div>
          <div
            className={`${
              loc === "users" ? "account-left--active" : ""
            } account-left__users`}
          >
            <button
              onClick={() => {
                navigate("/account/users");
                setOpen(false);
              }}
            >
              Users
            </button>
          </div>
        </div>
      ) : (
        <div className="account-left">
          <div className="account-left__top">
            <div className="account-left__all-recordings">
              <div
                className={`${
                  loc === "all-recordings" ? "account-left--active" : ""
                }`}
              >
                <button onClick={() => navigate("/project/all-recordings")}>
                  All Recordings
                </button>
              </div>
            </div>
            <div className="account-left__projects">
              <div>
                <button onClick={() => setProjectOpen(!projectOpen)}>
                  <span>
                    Projects :
                    {cookies?.user?.plan.plan_type === "free" ||
                    cookies?.user?.plan.plan_type === "premium" ||
                    cookies?.user?.plan.plan_type === "vip"
                      ? ` ${getProjectNamesAndIds().length}`
                      : ` ${getProjectNamesAndIds().length}/${
                          cookies?.user?.plan.max_num_podcasts
                        }`}
                  </span>
                  {getProjectNamesAndIds().length > 0 && (
                    <>
                      {projectOpen ? (
                        <KeyboardArrowUpOutlinedIcon />
                      ) : (
                        <KeyboardArrowDownOutlinedIcon />
                      )}
                    </>
                  )}
                </button>
              </div>
              <div
                className={`${
                  !projectOpen ? "account-left__projects-list--hide" : ""
                } account-left__projects-list`}
              >
                {getProjectNamesAndIds().map((pnu) => {
                  return (
                    <div
                      key={pnu.id}
                      onClick={() => {
                        navigate(`/project/${pnu.id}`);
                        stopAll();
                      }}
                      className={`${
                        loc === pnu.id ? "account-left-div--active" : ""
                      } account-left__projects-list__items`}
                    >
                      <div>{pnu.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="account-left__add">
              <div
                className={`${
                  loc === "add-project" ? "account-left--active" : ""
                }`}
              >
                <Tooltip
                  title={
                    getProjectNamesAndIds().length /
                      cookies?.user?.plan.max_num_podcasts >=
                    1
                      ? "Upgrade to create more projects"
                      : ""
                  }
                >
                  <button
                    disabled={
                      getProjectNamesAndIds().length /
                        cookies?.user?.plan.max_num_podcasts >=
                      1
                        ? true
                        : false
                    }
                    onClick={() => handelCheck()}
                  >
                    <AddCircleIcon />
                    <span>Add a Project </span>
                  </button>
                </Tooltip>
              </div>
            </div>
            <div
              className={`${
                loc === "users" ? "account-left--active" : ""
              } account-left__users`}
            >
              <button onClick={() => navigate("/account/users")}>Users</button>
            </div>
          </div>
          {currentPlaying && (
            <div className="account-left__bottom">
              <SidebarCardComponent />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AccountSidebar;
