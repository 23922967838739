import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from ".";
import { SnackBarContext } from "../";

const AuthProvider = ({ children }) => {
  const { showSnackbar } = useContext(SnackBarContext);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  const fetchUserDetails = useCallback(async () => {
    const user = localStorage.getItem("data");
    const { user_id, token } = JSON.parse(user);
    try {
      const { data } = await axios.get(`users/${user_id}`, {
        params: { token },
      });
      setUserDetails(data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("data");
        showSnackbar({
          open: true,
          message: "You are UnAuthorized. Login Required",
          type: "error",
        });
        navigate("/login", { replace: true });
      }
      console.log(error.response.status);
    }
  });

  return (
    <AuthContext.Provider value={{ userDetails, fetchUserDetails }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
