import { useContext } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCookies } from "react-cookie";
import axios from "axios";

import { SnackBarContext } from "../../../providers/snackbar/context";
import ButtonComponent from "../../../components/button/button";
import AccountHeading from "../account-heading";

import "./index.css";

const GeneralSection = () => {
  const { showSnackbar } = useContext(SnackBarContext);
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [cookie, setCookie] = useCookies(["token", "user"]);
  const schema =
    cookie?.user?.googleId || cookie?.user?.facebookId
      ? yup
          .object({
            username: yup
              .string()
              .min(6, "Username sould contain Minimum 6 Characters")
              .required("Username is required!"),
            new_password: yup.string().when("$exist", () => {
              if (newPass !== "")
                return yup
                  .string()
                  .min(6, "should contain minimum 6 characters!");
            }),
            confirm_password: yup.string().when("$exist", () => {
              if (confirmPass !== newPass)
                return yup
                  .string()
                  .min(6, "should contain minimum 6 characters!");
            }),
          })
          .required()
      : yup
          .object({
            email: yup
              .string()
              .email("Please provide a valid email address!")
              .required("Email is required!"),
            username: yup
              .string()
              .min(6, "Username sould contain Minimum 6 Characters")
              .required("Username is required!"),
            current_password: yup.string().when("$exist", () => {
              if (currentPass !== "")
                return yup
                  .string()
                  .min(6, "Should contain minimum 6 characters!");
            }),
            new_password: yup.string().when("$exist", () => {
              if (newPass !== "")
                return yup
                  .string()
                  .min(6, "should contain minimum 6 characters!");
            }),
            confirm_password: yup.string().when("$exist", () => {
              if (confirmPass !== newPass)
                return yup
                  .string()
                  .min(6, "should contain minimum 6 characters!");
            }),
          })
          .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (cookie?.token && cookie?.user) {
      setUsername(cookie?.user?.username);
      setUserEmail(cookie?.user?.email);
      reset({ username: cookie?.user?.username, email: cookie?.user?.email });
    }
  }, []);

  const shouldDisable = () => {
    if (cookie?.user?.googleId || cookie?.user?.facebookId) {
      if (newPass && confirmPass && newPass === confirmPass) return false;
    } else {
      if (currentPass && newPass && confirmPass && newPass === confirmPass)
        return false;
    }
    if (
      cookie?.user?.username !== username ||
      cookie?.user?.email !== userEmail
    )
      return false;
    return true;
  };

  const onSubmit = (e) => {
    if (
      cookie?.user?.username !== username ||
      cookie?.user?.email !== userEmail
    ) {
      axios
        .put(
          `auth/update-user/${cookie?.user?.id}`,
          {
            email: e.email,
            username: e.username,
          },
          {
            headers: {
              token: cookie?.token,
            },
          }
        )
        .then((data) => {
          showSnackbar({
            open: true,
            message: "Updated the Settings",
            type: "success",
          });
          setCookie("user", {
            ...cookie?.user,
            username: e.username,
            email: e.email,
          });
          reset({ username: e.username, email: e.email });
          setNewPass("");
          setCurrentPass("");
          setConfirmPass("");
        })
        .catch((err) => {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Failed to update the user!",
            type: err.response.data.status || "error",
          });
        });
    }
    if (
      (currentPass && newPass && confirmPass && newPass === confirmPass) ||
      ((cookie?.user?.googleId || cookie?.user?.facebookId) &&
        newPass &&
        confirmPass &&
        newPass === confirmPass)
    ) {
      const obj = {
        new_password: e.new_password,
        password: e.new_password,
        email: e.email,
      };
      if (!(cookie?.user?.googleId || cookie?.user?.facebookId)) {
        obj["current_password"] = e.current_password;
      } else {
        if (cookie?.user?.googleId) {
          obj["googleId"] = cookie?.user?.googleId;
          obj["current_password"] = e.new_password;
        } else if (cookie?.user?.facebookId) {
          obj["facebookId"] = cookie?.user?.facebookId;
          obj["current_password"] = e.new_password;
        }
      }

      axios
        .put(`auth/change-password`, obj, {
          headers: {
            token: cookie?.token,
          },
        })
        .then((data) => {
          showSnackbar({
            open: true,
            message: "Updated the Settings",
            type: "success",
          });
          reset({
            email: e.email,
            username: e.username,
            current_password: "",
            new_password: "",
            confirm_password: "",
          });
        })
        .catch((err) => {
          // console.log(err);
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error?.toString() ||
              err.response?.data?.message?.toString() ||
              "Failed to change the password!",
            type: err?.response?.data?.status?.toString() || "error",
          });
        });
    }
  };

  return (
    <div className="general-section">
      <AccountHeading
        heading="General"
        margin="4px 0 16px 0"
        children={
          <form
            className="general-section-input"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="text"
              placeholder="Username"
              name="name"
              id="name"
              {...register("username", {
                onChange: (e) => setUsername(e.target.value),
              })}
              aria-invalid={errors.username ? "true" : "false"}
            />
            {errors.username && (
              <p
                role="alert"
                className="auth-tab__error auth-tab__error--alter"
              >
                {errors.username?.message}
              </p>
            )}
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              disabled={cookie?.user?.googleId || cookie?.user?.facebookId}
              {...register("email", {
                onChange: (e) => setUserEmail(e.target.value),
              })}
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && (
              <p
                role="alert"
                className="auth-tab__error auth-tab__error--alter"
              >
                {errors.email?.message}
              </p>
            )}
            {!(cookie?.user?.googleId || cookie?.user?.facebookId) && (
              <>
                <input
                  type="password"
                  placeholder="Current Password"
                  name="cu-pass"
                  id="cu-pass"
                  value={currentPass}
                  disabled={cookie?.user?.googleId || cookie?.user?.facebookId}
                  {...register("current_password", {
                    onChange: (e) => setCurrentPass(e.target.value),
                  })}
                  aria-invalid={errors.current_password ? "true" : "false"}
                />
                {errors.current_password && (
                  <p
                    role="alert"
                    className="auth-tab__error auth-tab__error--alter"
                  >
                    {errors.current_password?.message}
                  </p>
                )}
              </>
            )}
            <input
              type="password"
              placeholder="New Password"
              name="n-pass"
              id="n-pass"
              value={newPass}
              {...register("new_password", {
                onChange: (e) => setNewPass(e.target.value),
              })}
              aria-invalid={errors.new_password ? "true" : "false"}
            />
            {errors.new_password && (
              <p
                role="alert"
                className="auth-tab__error auth-tab__error--alter"
              >
                {errors.new_password?.message}
              </p>
            )}
            <input
              type="password"
              placeholder="Confirm Password"
              name="co-pass"
              id="co-pass"
              value={confirmPass}
              {...register("confirm_password", {
                onChange: (e) => setConfirmPass(e.target.value),
              })}
              aria-invalid={errors.confirm_password ? "true" : "false"}
            />
            {errors.confirm_password && (
              <p
                role="alert"
                className="auth-tab__error auth-tab__error--alter"
              >
                {errors.confirm_password?.message}
              </p>
            )}
            <div>
              <ButtonComponent
                text="Save"
                type="filled"
                disabled={shouldDisable()}
                submit={!shouldDisable()}
              />
            </div>
          </form>
        }
      />
    </div>
  );
};

export default GeneralSection;
