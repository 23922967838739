import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { starterList, premiumList, proList } from "../utils/const";
import useWidth from "../hooks/use-width";
import axios from "axios";
import PricingTable from "../components/pricing-table/pricing-table";
import TabNavsComponent from "../components/tab-navs/tab-navs";
import PricingCards from "../components/pricing-cards/pricing-cards";
import { createSubscription } from "../utils/createSession";
import "../styles/pricing.css";
import { startTrial } from "../utils/createSession";
import { useCookies } from "react-cookie";
import { SnackBarContext } from "../providers/snackbar";
import { prices } from "../utils/const";

const PricingPage = () => {
  const { showSnackbar } = useContext(SnackBarContext);
  const trial = useRef(null);
  const [products, setProducts] = useState([]);
  const [tabs, setTabs] = useState("");
  const [trialStatus, setTrialStatus] = useState(false);
  const [plan, setPlan] = useState("free");
  const [planType, setPlanType] = useState("free");
  const [type, setType] = useState("free");

  const width = useWidth();

  const [cookies, setCookies] = useCookies(["token", "user"]);
  const navigate = useNavigate();

  const fetchPricing = async () => {
    try {
      const res = await axios.get(`subscription/pay`);

      setProducts(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handelSubs = (priceId, plan, type) => {
    if (cookies?.user)
      cookies?.user?.plan?.plan_type === "vip"
        ? showSnackbar({
            open: true,
            message: "You are a VIP",
            type: "success",
          })
        : createSubscription(priceId, plan, type, cookies?.token);
    else navigate("/auth/signup");
  };

  useEffect(() => {
    tabs === "annually" ? setType("yr") : setType("mo");
  }, [tabs]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("trial")) {
      if (!cookies?.user?.trial_status)
        trial.current?.scrollIntoView({ behavior: "smooth" });
    }
    fetchPricing();
    if (cookies?.user?.plan) {
      setPlan(cookies?.user?.plan?.plan_type);
      setPlanType(cookies?.user?.plan?.type);
      setTrialStatus(cookies?.user?.trial_status);
    } else {
      setPlan("free");
      setTrialStatus(false);
    }
  }, [cookies]);

  return (
    <div className="pricing">
      <div className="pricing--align">
        <div>
          {plan === "free" && trialStatus === false && cookies?.user ? (
              <>
                <div className="text-align" style={{
                  justifyContent:"center",
                  alignItems:"center",
                  display:"flex",
                  flexDirection:"column"
                }}>
                  <h1 style={{marginBottom:"2rem"}}>
                    Try it for free 30 days!
                  </h1>
                  <p style={{marginTop:0,marginBottom:"1.5rem"}}>No credit card required. No commitment</p>
                  <button
                      ref={trial}
                      className="animated-button1"
                      onClick={async () => {
                        const user = await startTrial(cookies?.token);
                        const expires = new Date();
                        expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
                        setCookies("user", user, {
                          path: "/",
                          expires: expires,
                        });
                        showSnackbar({
                          open: true,
                          message: "Successfully started the trial!",
                          type: "success",
                        });
                        navigate("/account/settings");
                      }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Start Your free trial
                  </button>
                </div>
              </>
          ) : null}
          {!cookies?.user ? (
              <>
                <div className="text-align" style={{
                  justifyContent:"center",
                  alignItems:"center",
                  display:"flex",
                  flexDirection:"column"
                }}>
                  <h1>
                    Try it for free 30 days!
                  </h1>
                  <p style={{marginTop:0,marginBottom:20}}>No credit card required. No commitment</p>
                  <button
                      ref={trial}
                      className="animated-button1"
                      onClick={async () => {
                        navigate("/auth/login");
                      }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Start Trial
                  </button>
                </div>
              </>
          ) : null}
        </div>
        <div className="pricing-head">Pricing</div>
        <div className="pricing-tab-menu">
          <TabNavsComponent setTabs={setTabs} tabs={tabs} type="month" />
          {width > 950 ? (
            <>
              {tabs === "annually" ? (
                <PricingTable
                  type={"yr"}
                  staPrice={"39"}
                  staId={products[4]?.id}
                  proPrice={"179"}
                  proId={products[2]?.id}
                  prePrice={"479"}
                  preId={products[0]?.id}
                />
              ) : (
                <PricingTable
                  type={"mo"}
                  staPrice={"5"}
                  staId={products[5]?.id}
                  proPrice={"19"}
                  proId={products[3]?.id}
                  prePrice={"49"}
                  preId={products[1]?.id}
                />
              )}
            </>
          ) : (
            <div className="pricing-tab-menu__cards">
              <PricingCards
                inverted={false}
                title="starter"
                li={starterList}
                btn={true}
                pricing={{
                  p: tabs === "annually" ? 39 : 5,
                  dur: tabs === "annually" ? "yr" : "mo",
                }}
                disabled={
                  plan === "starter" && planType === type ? true : false
                }
                onClick={() =>
                  tabs === "annually"
                    ? handelSubs(prices.starter.anu_priceId, "starter", "yr")
                    : handelSubs(prices.starter.mon_priceId, "starter", "mo")
                }
              />
              <PricingCards
                inverted={false}
                btn={true}
                title="Pro"
                li={proList}
                pricing={{
                  p: tabs === "annually" ? 179 : 19,
                  dur: tabs === "annually" ? "yr" : "mo",
                }}
                disabled={plan === "pro" && planType === type ? true : false}
                onClick={() =>
                  tabs === "annually"
                    ? handelSubs(prices.pro.anu_priceId, "pro", "yr")
                    : handelSubs(prices.pro.mon_priceId, "pro", "mo")
                }
              />
              <PricingCards
                inverted={true}
                btn={true}
                title="Premium"
                li={premiumList}
                pricing={{
                  p: tabs === "annually" ? 479 : 49,
                  dur: tabs === "annually" ? "yr" : "mo",
                }}
                disabled={
                  plan === "Premium" && planType === type ? true : false
                }
                onClick={() =>
                  tabs === "annually"
                    ? handelSubs(prices.premium.anu_priceId, "Premium", "yr")
                    : handelSubs(prices.premium.mon_priceId, "Premium", "mo")
                }
              />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default PricingPage;
