import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ButtonComponent from "../components/button/button";
import { SnackBarContext } from "../providers/snackbar";
import { CircularProgress } from "@mui/material";
import "../styles/forgot-password.css";

const ForgotPassword = () => {
  const [changePass, setChangePass] = useState("");
  const [loading, setLoading] = useState(false);
  const [pinStatus, setPinStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useContext(SnackBarContext);

  const schema =
    changePass === "change-password"
      ? yup
          .object({
            email: yup
              .string()
              .email("Please provide a valid email address!")
              .required("Email is required!"),
            pin: yup
              .string()
              .required("Pin is required!")
              .matches(/^[0-9]+$/, "Pin should be 4 digit character!")
              .min(4, "Pin should have 4 characters!")
              .max(4, "Pin should have 4 characters!"),
            new_password: yup
              .string()
              .required("Password is required!")
              .min(6, "Password should have minimum 6 characters!"),
          })
          .required()
      : yup
          .object({
            email: yup
              .string()
              .email("Please provide a valid email address!")
              .required("Email is required!"),
          })
          .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (changePass === "change-password") {
      axios
        .put("auth/forget-password", data)
        .then((data) => {
          setLoading(false);
          showSnackbar({
            open: true,
            message: "Password is resetted successfully!",
            type: "success",
          });
          reset();
          navigate("/auth/login");
        })
        .catch((err) => {
          setLoading(false);
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Failed to reset the password!",
            type: err.response.data.status || "error",
          });
        });
    } else {
      setPinStatus(true);
      axios
        .post("auth/send-pin", data)
        .then((data) => {
          setLoading(false);
          showSnackbar({
            open: true,
            message: "Pin sent to email successfully!",
            type: "success",
          });
          reset();
          setPinStatus(false);
          navigate("/forgot-password?change-password");
        })
        .catch((err) => {
          setLoading(false);
          setPinStatus(false);
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Error sending pin!",
            type: err.response.data.status || "error",
          });
        });
    }
  };

  useEffect(() => {
    let loc = location.search.split("?")[1];
    if (loc !== changePass) setChangePass(loc);
  }, [location]);

  return (
    <div className="forgot-password">
      <div className="forgot-password--align">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="forgot-password__form"
        >
          {changePass === "change-password" ? (
            <>
              <h2>Provide Pin and Password below to reset your password:</h2>
              <input
                type="email"
                placeholder="Email"
                name="email"
                id="auth-email"
                {...register("email")}
                aria-invalid={errors.email ? "true" : "false"}
              />
              {errors.email && (
                <p role="alert" className="auth-tab__error">
                  {errors.email?.message}
                </p>
              )}
              <span style={{ height: 16 }} />
              <input
                type="text"
                placeholder="Pin"
                name="pin"
                id="auth-pin"
                {...register("pin")}
                aria-invalid={errors.pin ? "true" : "false"}
              />
              {errors.pin && (
                <p role="alert" className="auth-tab__error">
                  {errors.pin?.message}
                </p>
              )}
              <span style={{ height: 16 }} />
              <input
                type="password"
                placeholder="New Password"
                name="new_password"
                id="auth-password"
                {...register("new_password")}
                aria-invalid={errors.new_password ? "true" : "false"}
              />
              {errors.new_password && (
                <p role="alert" className="auth-tab__error">
                  {errors.new_password?.message}
                </p>
              )}
            </>
          ) : (
            <>
              <h2>Provide email below to reset your password:</h2>
              <input
                type="email"
                placeholder="Email"
                name="email"
                id="auth-email"
                {...register("email")}
                aria-invalid={errors.email ? "true" : "false"}
              />
              {errors.email && (
                <p role="alert" className="auth-tab__error">
                  {errors.email?.message}
                </p>
              )}
            </>
          )}
          <span style={{ height: 25 }} />
          <div className="forgot-password__submit-btn">
            <ButtonComponent
              submit={true}
              text={
                changePass === "change-password"
                  ? "Update Password"
                  : "Send Pin"
              }
              type="filled"
              disabled={loading}
            />
          </div>
          {pinStatus ? <CircularProgress /> : <></>}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
