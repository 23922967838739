import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import HomeTop from "../icons/svg/home-top.svg";
import VoiceVibration from "../icons/svg/voice-vibration.svg";
import HomeSmall from "../icons/svg/home-small.svg";
import Info from "../icons/svg/info.svg";
import ButtonComponent from "../components/button/button";
import SwipeComponent from "../components/swipe/swipe";
import useWindowWidth from "../hooks/use-width";

import "../styles/home.css";

const HomePage = () => {
  const [cookies] = useCookies(["token", "user"]);
  const navigate = useNavigate();
  const width = useWindowWidth();

  return (
    <div className="home">
      <div className="home__top-img__wrapper">
        <div>
          <h1 style={{display:"flex",justifyContent:"center"}}>
            Give a&nbsp;
            <svg
              width="134"
              height="37"
              viewBox="0 0 134 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.49316 0L16.8616 28.5644L14.2345 28.039L25.0297 0H30.4273L15.1898 36.1116L0 0H5.49316Z"
                fill="white"
              />
              <path
                d="M29.1589 24.4565C29.1589 22.4822 29.6207 20.6989 30.5441 19.1067C31.4995 17.5144 32.8051 16.2566 34.461 15.3331C36.1169 14.4096 37.9957 13.9479 40.0975 13.9479C42.2629 13.9479 44.1576 14.4096 45.7817 15.3331C47.4058 16.2566 48.6636 17.5144 49.5553 19.1067C50.4469 20.6989 50.8927 22.4822 50.8927 24.4565C50.8927 26.4309 50.4469 28.2301 49.5553 29.8541C48.6636 31.4464 47.3899 32.7042 45.7339 33.6277C44.1099 34.5512 42.2151 35.0129 40.0497 35.0129C37.948 35.0129 36.0692 34.583 34.4132 33.7232C32.7892 32.8316 31.4995 31.6056 30.5441 30.0452C29.6207 28.453 29.1589 26.5901 29.1589 24.4565ZM33.6012 24.5043C33.6012 25.7781 33.8878 26.9404 34.461 27.9912C35.0342 29.0103 35.7985 29.8223 36.7538 30.4273C37.741 31.0324 38.8237 31.3349 40.0019 31.3349C41.2439 31.3349 42.3425 31.0324 43.2978 30.4273C44.285 29.8223 45.0493 29.0103 45.5906 27.9912C46.132 26.9404 46.4027 25.7781 46.4027 24.5043C46.4027 23.2305 46.132 22.0841 45.5906 21.0651C45.0493 20.0142 44.285 19.1863 43.2978 18.5812C42.3425 17.9443 41.2439 17.6259 40.0019 17.6259C38.7919 17.6259 37.6932 17.9443 36.706 18.5812C35.7507 19.2181 34.9864 20.062 34.4132 21.1129C33.8719 22.1319 33.6012 23.2624 33.6012 24.5043Z"
                fill="white"
              />
              <path
                d="M109.016 32.72C108.124 33.4206 107.057 33.9779 105.815 34.3919C104.573 34.8059 103.332 35.0128 102.09 35.0128C99.9242 35.0128 98.0294 34.5829 96.4054 33.7231C94.7813 32.8633 93.5075 31.6692 92.584 30.1406C91.6924 28.5803 91.2466 26.7492 91.2466 24.6475C91.2466 22.5457 91.7242 20.7147 92.6796 19.1543C93.6668 17.5939 94.9405 16.3998 96.5009 15.5718C98.0931 14.712 99.7968 14.2821 101.612 14.2821C103.204 14.2821 104.621 14.505 105.863 14.9509C107.137 15.3967 108.22 16.0017 109.111 16.766L106.914 19.632C106.341 19.1862 105.64 18.7881 104.812 18.4378C103.984 18.0875 103.045 17.9124 101.994 17.9124C100.848 17.9124 99.7809 18.2149 98.7937 18.82C97.8384 19.3932 97.0741 20.1893 96.5009 21.2083C95.9596 22.2273 95.6889 23.3737 95.6889 24.6475C95.6889 25.8576 95.9755 26.988 96.5487 28.0389C97.1219 29.0579 97.918 29.87 98.937 30.475C99.956 31.0482 101.118 31.3348 102.424 31.3348C103.284 31.3348 104.064 31.2074 104.765 30.9527C105.497 30.6979 106.15 30.3476 106.723 29.9018L109.016 32.72Z"
                fill="white"
              />
              <path
                d="M123.922 35.0128C121.661 35.0128 119.718 34.567 118.094 33.6754C116.47 32.7519 115.212 31.51 114.32 29.9496C113.461 28.3574 113.031 26.5422 113.031 24.5042C113.031 22.5617 113.524 20.7943 114.512 19.2021C115.499 17.6099 116.82 16.3361 118.476 15.3808C120.132 14.4254 121.979 13.9478 124.017 13.9478C126.628 13.9478 128.794 14.712 130.513 16.2406C132.233 17.7691 133.395 19.8708 134 22.5457L117.712 28.2777L116.661 25.6506L130.036 20.7784L129.08 21.3994C128.698 20.3485 128.061 19.4409 127.17 18.6767C126.278 17.8805 125.116 17.4825 123.683 17.4825C122.473 17.4825 121.39 17.785 120.435 18.3901C119.479 18.9633 118.731 19.7594 118.19 20.7784C117.648 21.7974 117.378 22.9597 117.378 24.2653C117.378 25.6347 117.664 26.8447 118.237 27.8956C118.811 28.9146 119.591 29.7267 120.578 30.3317C121.597 30.9049 122.743 31.1915 124.017 31.1915C124.877 31.1915 125.705 31.0323 126.501 30.7138C127.329 30.3954 128.093 29.9814 128.794 29.4719L130.848 32.7678C129.861 33.4365 128.746 33.9779 127.504 34.3919C126.294 34.8059 125.1 35.0128 123.922 35.0128Z"
                fill="white"
              />
              <path
                d="M60.7361 27.6004V17.5868M71.185 33.0426V12.1447M81.634 26.512V18.6753"
                stroke="white"
                strokeWidth="4.8"
                strokeLinecap="round"
              />
            </svg>
            &nbsp;to your audience
          </h1>
          <p>and let them be part of your shows!</p>
        </div>
        <img
          className="home__top-img"
          src={width > 800 ? HomeTop : HomeSmall}
          alt="home top"
        />
      </div>
      <img
        className="home__voice-vibration"
        src={VoiceVibration}
        alt="voice vibration"
      />
      <div className="home-swipe">
        <SwipeComponent />
      </div>
      <img
        className="home__voice-vibration"
        src={VoiceVibration}
        alt="voice vibration"
      />
      <div className="home__info-img__wrapper">
        <p>Bring more conversations to your podcast!</p>
        <img className="home__info-img" src={Info} alt="info" />
        <p>Plug everything into your edit or play live during your show!</p>
      </div>

      <div className="home-action-content">
        <h1>Get Started Now</h1>
        <div className="home-action-content__btns">
          {cookies?.user?.trial_status === false &&
          cookies?.user?.plan.plan_type !== "vip" ? (
            <ButtonComponent
              onClick={() => navigate("/pricing")}
              text="Start for Free"
              type="filled"
            />
          ) : (
            <></>
          )}
          <ButtonComponent
            onClick={() => navigate("/pricing")}
            text="See Pricing"
            type="outlined"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
