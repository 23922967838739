import { useContext, useState } from "react";

import Waveform from "../waveform-audio/waveform-audio";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as ShareIcon } from "../../icons/svg/share.svg";
import { ReactComponent as BinIcon } from "../../icons/svg/bin.svg";
import RecordContext from "../../providers/records/record-context";

import "./audio-stick.css";

const AudioStick = ({
  url,
  title,
  project,
  index,
  date,
  id,
  liked,
  playBool,
  edit,
  setBulkDelete,
  bulkDelete,
  senderName,
}) => {
  const { toggleLike, deleteRecording } = useContext(RecordContext);
  const [duration, setDuration] = useState(null);
  const [destroy, setDestroy] = useState(false);
  const [waveLoading, setWaveLoading] = useState(true);

  const delRec = () => {
    if (waveLoading) return;
    deleteRecording(id, setDestroy);
  };

  const markAudioStick = (e) => {
    const checked = e.target.checked;
    if (checked) setBulkDelete([...bulkDelete, id]);
    else {
      const filteredDelete = bulkDelete.filter((e) => e !== id);
      setBulkDelete(filteredDelete);
    }
  };

  const downloadEmployeeData = (url) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${project}- Recorded by ${senderName}.wav`;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  return (
    <div className="audio-stick">
      <div className="audio-stick__align">
        {edit && (
          <div className="audio-stick__edit">
            <input type="checkbox" onClick={markAudioStick} />
          </div>
        )}
        <div className="audio-stick__dets">
          <p style={{ margin: 0 }}>{project}</p>
          <h2>Recorded by {senderName}</h2>
          <div className="audio-stick__dets-small">
            <small>#{index}</small>
            <div className="audio-stick-dot" />
            <small>{date}</small>
            <div className="audio-stick-dot" />
            <small>{!duration ? "..." : duration} mins</small>
          </div>
        </div>
        <div className="audio-stick__audio">
          <Waveform
            url={url}
            setDuration={setDuration}
            id={id}
            playBool={playBool}
            destroy={destroy}
            setWaveLoading={setWaveLoading}
            liked={liked}
          />
        </div>
        <div className="audio-stick__icons">
          {!liked ? (
            <FavoriteBorderIcon onClick={() => toggleLike(id, true)} />
          ) : (
            <FavoriteIcon
              onClick={() => toggleLike(id, false)}
              style={{
                color: "#FF3333",
              }}
            />
          )}

          <FileDownloadOutlinedIcon
            onClick={() => {
              downloadEmployeeData(url);
            }}
          />

          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ShareIcon />
          </a>
          <BinIcon onClick={() => delRec()} />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default AudioStick;
