import { useCookies } from "react-cookie";

import AccountLinkingSection from "./account-linking-section";
// import BillingSection from "./billing-section";
import GeneralSection from "./general-section";
import SubscriptionSection from "./subscription-section";

import "./index.css";

const AllSettings = () => {
  const [cookies] = useCookies(["user"]);

  return (
    <>
      <div className="all-settings">
        <h1 className="all-settings__heading">Account Settings</h1>
        <GeneralSection />
        {(cookies?.user?.googleId || cookies?.user?.googleId) && (
          <AccountLinkingSection />
        )}

        {/* <BillingSection /> */}
        <div id="subs-scroll" />
      </div>
      <div>
        <SubscriptionSection />
      </div>
    </>
  );
};

export default AllSettings;
