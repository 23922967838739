import { useState } from "react";
import { ReactComponent as PlayGrayIcon } from "../../icons/svg/play-gray.svg";
import { ReactComponent as PlayDarkIcon } from "../../icons/svg/play-dark.svg";
import { ReactComponent as PauseGrayIcon } from "../../icons/svg/pause-gray.svg";
import { ReactComponent as PauseDarkIcon } from "../../icons/svg/pause-dark.svg";
import { ReactComponent as RewindIcon } from "../../icons/svg/rewind.svg";
import { ReactComponent as RerecordDarkIcon } from "../../icons/svg/rerecord-dark.svg";
import { ReactComponent as MicIcon } from "./mic.svg";
import ButtonComponent from "../button/button";
import RecWavePlay from "./rec-wave/rec-wave-play";

const Player = ({ setBlobObj, blobObj, setRecState, setSubmit, locObj }) => {
  const [play, setPlay] = useState(false);

  return (
    <div className="wc-player">
      <div className="wc-player-top">
        <>
          {locObj.controls === "light" ? (
            <RewindIcon
              style={{ cursor: "pointer" }}
              className="wc-player--rewind"
              onClick={() => {
                setBlobObj(null);
                setRecState("recording");
              }}
            />
          ) : (
            <RerecordDarkIcon
              style={{ cursor: "pointer" }}
              className="wc-player--rewind"
              onClick={() => {
                setBlobObj(null);
                setRecState("recording");
              }}
            />
          )}
        </>
        {!play ? (
          <>
            {locObj.controls === "light" ? (
              <PauseGrayIcon
                style={{ cursor: "pointer" }}
                className="wc-player--play"
                onClick={() => setPlay(true)}
              />
            ) : (
              <PauseDarkIcon
                style={{ cursor: "pointer" }}
                className="wc-player--play"
                onClick={() => setPlay(true)}
              />
            )}
          </>
        ) : (
          <>
            {locObj.controls === "light" ? (
              <PlayGrayIcon
                style={{ cursor: "pointer" }}
                className="wc-player--play"
                onClick={() => setPlay(false)}
              />
            ) : (
              <PlayDarkIcon
                style={{ cursor: "pointer" }}
                className="wc-player--play"
                onClick={() => setPlay(false)}
              />
            )}
          </>
        )}
        <div className="wave-play--wrapper">
          <RecWavePlay
            blobObj={blobObj}
            play={play}
            setPlay={setPlay}
            controls={locObj.controls}
          />
        </div>
      </div>
      <div className="wc-player-bottom">
        <button
          onClick={() => setSubmit(true)}
          className="widget-card__record-btn"
          style={{
            background: locObj.btn_color ? `${locObj.btn_color}` : "#3864ff",
            color:
              locObj && locObj.btn_text_color
                ? `${locObj.btn_text_color}`
                : "#ffffff",
          }}
        >
          Submit
        </button>
        <button
          onClick={() => {
            setBlobObj(null);
            setRecState("recording");
          }}
          className="widget-card__record-btn"
          style={{
            background: locObj.btn_color ? `${locObj.btn_color}` : "#3864ff",
            color:
              locObj && locObj.btn_text_color
                ? `${locObj.btn_text_color}`
                : "#ffffff",
          }}
        >
          <div>
            <MicIcon
              style={{
                fill: locObj.btn_text_color
                  ? `${locObj.btn_text_color}`
                  : "#ffffff",
              }}
            />
          </div>
          Record Again
        </button>
      </div>
    </div>
  );
};
export default Player;
