import { useCookies } from "react-cookie";

import AccountHeading from "../account-heading";
import IconButtonComponent from "../../../components/icon-button/icon-button";
import GoogleIcon from "../../../icons/svg/google.svg";
import FacebookIcon from "../../../icons/svg/facebook.svg";

import "./index.css";

const AccountLinkingSection = () => {
  const [cookies] = useCookies();

  return (
    <div className="account-linking-section">
      <AccountHeading
        margin="40px 0 24px 0"
        heading="Account Linking"
        children={
          <div className="account-linking-section__btns">
            {cookies?.user?.googleId && (
              <IconButtonComponent
                style={{ marginBottom: 16, cursor: "default" }}
                Icon={GoogleIcon}
                text={`${
                  cookies?.user?.googleId ? "Linked" : "Login"
                } with Google`}
                className={`${
                  cookies?.user?.googleId ? "account-linking__linked" : ""
                }`}
              />
            )}
            {cookies?.user?.facebookId && (
              <IconButtonComponent
                Icon={FacebookIcon}
                style={{ cursor: "default" }}
                text={`${
                  cookies?.user?.facebookId ? "Linked" : "Login"
                } with Facebook`}
                className={`${
                  cookies?.user?.facebookId ? "account-linking__linked" : ""
                }`}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default AccountLinkingSection;
