import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";

import RecordContext from "../../providers/records/record-context";

import WaveSurfer from "wavesurfer.js";
import { CircularProgress } from "@mui/material";
import { ReactComponent as WavePlay } from "../../icons/svg/wave-play.svg";
import { ReactComponent as WavePause } from "../../icons/svg/wave-pause.svg";

import "./waveform-audio.css";

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#BDBDBD",
  progressColor: "#3864FF",
  cursorColor: "transparent",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 80,
  width: "100%",
  position: "static",
  normalize: true,
  partialRender: true,
  cursor: "pointer",
  hideScrollbar: true,
  scrollParent: true,
});

function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
}

export default function Waveform({
  url,
  setDuration,
  playBool,
  id,
  destroy,
  setWaveLoading,
  liked,
}) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const sideBarWaves = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingPer, setLoadingPer] = useState(0);
  const [playtime, setPlaytime] = useState({ time: "00:00" });
  const [play, setPlay] = useState(playBool);
  const {
    setPlaying,
    setSeek,
    setCompDuration,
    containerIdPrfix,
    sideBarContainerIdPrfix,
    currentPlaying,
    currentPlayingOnSideBar,
    setCurrentPlayingOnSideBar,
  } = useContext(RecordContext);

  if (play !== playBool) {
    setPlay(playBool);
    if (playBool) wavesurfer.current.play();
    else if (!playBool) wavesurfer.current.pause();
  }

  // useEffect(() => {
  //   setPlay(false);
  //   setPlaytime({ time: "00:00" });
  // }, []);

  useEffect(() => {
    setLoading(true);
    const audioContainer = `#${containerIdPrfix}${id}`;
    const options = formWaveSurferOptions(audioContainer);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(url);
  }, []);

  useEffect(() => {
    wavesurfer.current.on("loading", function (h) {
      setLoadingPer(h);
    });
    wavesurfer.current.on("audioprocess", function () {
      setSeek(id, wavesurfer?.current?.getCurrentTime());
      const hms = convertHMS(wavesurfer?.current?.getCurrentTime());
      if (hms !== playtime.time) setPlaytime({ time: hms });
    });
    wavesurfer.current.on("seek", function () {
      setSeek(id, wavesurfer?.current?.getCurrentTime());
    });
    wavesurfer.current.on("ready", function () {
      setLoading(false);
      setDuration(convertHMS(wavesurfer?.current?.getDuration()));
      setCompDuration(id, convertHMS(wavesurfer?.current?.getDuration()));
      setWaveLoading(false);
    });
    wavesurfer.current.on("finish", function () {
      setPlaying(id, false, liked);
      setPlay(false);
      setPlaytime({ time: "00:00" });
      setSeek(id, 0);
    });
    if (destroy) wavesurfer.current.destroy();
    return () => {
      wavesurfer.current.destroy();
      setPlay(false);
      // setPlaying(id, false, liked);
    };
  }, [url, destroy]);

  useEffect(() => {
    let hasWaves = document.querySelector(`#${sideBarContainerIdPrfix}`);
    if (currentPlaying?.playing && hasWaves?.children.length === 0) {
      if (
        currentPlayingOnSideBar &&
        currentPlayingOnSideBar !== currentPlaying?.id
      ) {
        sideBarWaves.current = null;
        setCurrentPlayingOnSideBar(currentPlaying?.id);
      }
      if (!sideBarWaves.current) {
        const options = formWaveSurferOptions(hasWaves);
        sideBarWaves.current = WaveSurfer.create(options);
        sideBarWaves.current.load(url);
        sideBarWaves.current.play();
      }
    } else {
      sideBarWaves.current?.pause();
    }
  }, [currentPlaying?.playing]);

  const handlePlayPause = () => {
    if (loading) return;
    setCurrentPlayingOnSideBar(currentPlaying?.id);
    const pp = wavesurfer.current.isPlaying();
    setPlaying(id, !pp, liked);
  };

  return (
    <div className="webform-audio">
      <button onClick={handlePlayPause} className="webform-pp-icons">
        {!playBool ? (
          <WavePlay className="webform-play-icon" />
        ) : (
          <WavePause className="webform-pause-icon" />
        )}
      </button>
      {loading ? (
        <div className="webform-audio__loading">
          <div>
            <CircularProgress style={{ color: "#bdbdbd" }} />
            &nbsp;&nbsp;&nbsp;{loadingPer}%
          </div>
        </div>
      ) : (
        <div
          className={`webform-audio__wave ${
            playBool ? "webform-audio__wave-playing" : ""
          }`}
          ref={waveformRef}
          id={`${containerIdPrfix}${id}`}
        />
      )}
      <div
        className={`webform-audio__number ${
          playBool ? "" : "webform-audio__number--paused"
        }`}
      >
        {playtime.time}
      </div>
    </div>
  );
}
