import React, { useCallback, useState } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SnackBarContext } from "./";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing + 2,
    },
  },
}));

const SnackBarProvider = ({ children }) => {
  const [align, setAlign] = useState({
    vertical: "top",
    horizontal: "right",
  });
  const [duration, setDuration] = useState(4000);
  const [error, setError] = useState({
    open: false,
    message: null,
    type: "success",
  });
  const classes = useStyles();
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError({ ...error, open: false, message: null });
  };

  let showSnackbar = useCallback(
    ({ open = false, message = null, type = "success" }) => {
      setError({ open, message, type });
    },
    []
  );

  return (
    <SnackBarContext.Provider value={{ setAlign, showSnackbar, setDuration }}>
      <div className={classes.root}>
        {children}
        <div>
          {error.open && (
            <div>
              <Snackbar
                open={error.open}
                autoHideDuration={duration}
                onClose={handleClose}
                anchorOrigin={{ ...align }}
              >
                <div>
                  <Alert onClose={handleClose} severity={error.type}>
                    <div>{error.message}</div>
                  </Alert>
                </div>
              </Snackbar>
            </div>
          )}
        </div>
      </div>
    </SnackBarContext.Provider>
  );
};
export { SnackBarProvider };
