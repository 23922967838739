const UserStick = ({ num, name, email }) => {
  return (
    <div className="userstick">
      <div className="userstick-content">
        <div>{num}</div>
        <div>{name}</div>
        <div
          style={{
            width: 4,
            height: 4,
            borderRadius: "100%",
            background: "#828282",
          }}
        />
        <div>{email}</div>
      </div>
      <hr />
    </div>
  );
};

export default UserStick;
