import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

import { SnackBarContext } from "../../providers/snackbar/context";
import { CircularProgress } from "@mui/material";

const SubmitForm = ({ fileObj, locObj, setRecState, setSubmit }) => {
  const [reqLoading, setReqLoading] = useState(false);
  const { showSnackbar } = useContext(SnackBarContext);
  const schema = yup.object({
    email: yup
      .string()
      .email("Please provide a valid email address!")
      .required("Email is required!")
      .max(50, "Email should contain at most 50 characters!"),
    name: yup
      .string()
      .min(3, "Name should have minimum 3 characters!")
      .max(30, "Name should contain at most 30 characters!")
      .required("Name is required!"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setReqLoading(true);
    const today = new Date();
    const dd = today.getDate();
    const mm = today.toLocaleString("default", { month: "short" });
    const yyyy = today.getFullYear();
    const file = new File([fileObj], "audio.webm", {
      type: fileObj.type,
    });
    const formData = new FormData();
    data?.name && formData.append("name", data?.name);
    data?.email && formData.append("email", data?.email);
    formData.append("proj_id", locObj._id || locObj.id);
    formData.append("project", locObj.name);
    formData.append("recording", file);
    formData.append("date", `${dd}-${mm}-${yyyy}`);
    axios
      .post("recording/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        setReqLoading(false);
        showSnackbar({
          open: true,
          message: "Your recording submitted successfully!",
          type: "success",
        });
        reset();
        setRecState("");
        setSubmit(false);
      })
      .catch((err) => {
        setReqLoading(false);
        showSnackbar({
          open: true,
          message:
            err.response?.data?.error ||
            err.response?.data?.message ||
            "Submission of recording failed",
          type: "error",
        });
      });
  };
  const IsBgColor = () => {
    if (locObj && locObj.bg === "true" && locObj.bg_color !== "false")
      return true;
    return false;
  };

  return (
    <form className="submit-form" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <input
          type="text"
          placeholder="Name"
          name="name"
          {...register("name")}
          aria-invalid={errors.name ? "true" : "false"}
          className={
            IsBgColor()
            ? "input-custom-theme"
            : "input-default-theme"
          }
          style={{
            color:
              locObj && locObj.text_color ? `${locObj.text_color}` : "#5a5a5a",
          }}
        />
        {errors.name && (
          <p role="alert" className="auth-tab__error auth-tab__error--widget">
            {errors.name?.message}
          </p>
        )}

        <input
          type="email"
          placeholder="Email"
          name="email"
          {...register("email")}
          aria-invalid={errors.email ? "true" : "false"}
          className={
            IsBgColor()
              ? "input-custom-theme"
              : "input-default-theme"
          }
          
          style={{
            color:
              locObj && locObj.text_color ? `${locObj.text_color}` : "#5a5a5a",
          }}
        />
        {errors.email && (
          <p role="alert" className="auth-tab__error auth-tab__error--widget">
            {errors.email?.message}
          </p>
        )}
      </div>
      <div>
        <button
          onClick={() => setSubmit(true)}
          className="widget-card__record-btn"
          style={{
            background: locObj.btn_color ? `${locObj.btn_color}` : "#3864ff",
            color:
              locObj && locObj.btn_text_color
                ? `${locObj.btn_text_color}`
                : "#ffffff",
          }}
        >
          Submit
        </button>
      </div>
      {reqLoading && (
        <div className="submit-form--loading">
          <CircularProgress />
        </div>
      )}
    </form>
  );
};

export default SubmitForm;
