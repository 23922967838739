import { useState } from "react";
import { useContext } from "react";
import { CSVLink } from "react-csv";

import RecordContext from "../../providers/records/record-context";
import UserStick from "./user-stick";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Close from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";

const AccountUser = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const { users, searchUser, getUserDetails, recLoading, removeDuplicates } =
    useContext(RecordContext);

  const checkSearch = (open) => {
    if (open === false) handleSearch("");
    setSearchOpen(open);
  };

  const handleSearch = (search) => {
    setSearchValue(search);
    searchUser(search);
  };

  useEffect(() => {
    setData(getUserDetails());
  }, []);

  const filtteredUsers  = removeDuplicates(users);


  return (
    <div className="account-users">
      <div className="account-users__top">
        <div>Users that sent recording</div>
        <div>
          {searchOpen ? (
            <div className="all-recs__search">
              <SearchOutlinedIcon />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                value={searchValue}
                autoFocus
              />
              <Close onClick={() => checkSearch(false)} />
            </div>
          ) : (
            <SearchOutlinedIcon
              onClick={() => checkSearch(true)}
              style={{ cursor: "pointer" }}
            />
          )}
          {!searchOpen && (
            <CSVLink
              data={data}
              separator={","}
              filename="users.csv"
              onClick={(_, done) => {
                setData(getUserDetails());
              }}
            >
              <button>
                <FileDownloadOutlinedIcon />
                Export as CSV
              </button>
            </CSVLink>
          )}
        </div>
      </div>
      <div className="account-users__bottom">
        {recLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {filtteredUsers?.length > 0 ? (
                filtteredUsers?.map((user, index) => (
                    <UserStick
                      key={index}
                      num={`0${index + 1}`}
                      name={user.name}
                      email={user.email}
                    />
              ))
            ) : (
              <div style={{ textAlign: "center", fontWeight: "bold" }}>
                No User to show!!!
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AccountUser;
