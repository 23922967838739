import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { RecordProvider } from "./providers/records";
import history from "./utils/history";
import AudioRecorder from 'audio-recorder-polyfill'

import { CookiesProvider } from "react-cookie";
import { AuthProvider, LoaderProvider, SnackBarProvider } from "./providers";


window.MediaRecorder = AudioRecorder

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter history={history}>
        <LoaderProvider>
          <SnackBarProvider>
            <AuthProvider>
              <RecordProvider>
                <App />
              </RecordProvider>
            </AuthProvider>
          </SnackBarProvider>
        </LoaderProvider>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

reportWebVitals();
