import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCookies } from "react-cookie";

import useWindowWidth from "../../hooks/use-width";
import RecordContext from "../../providers/records/record-context";
import { ReactComponent as MenuIcon } from "../../icons/svg/menu.svg";
import { ReactComponent as VoiiiceLogo } from "../../icons/svg/voiiice-logo.svg";
import { ReactComponent as HeadphoneIcon } from "../../icons/svg/headphone.svg";
import ButtonComponent from "../button/button";

import "./header.css";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [cookies] = useCookies(["token", "user"]);
  const [open, setOpen] = useState(false);
  const width = useWindowWidth();
  const { logout } = useContext(RecordContext);

  useEffect(() => {
    if (cookies?.token && cookies?.user) setLogin(true);
    if (!cookies?.token && !cookies?.user) setLogin(false);
  }, [cookies]);

  const NavList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onKeyDown={() => setOpen(false)}
      className="header-drawer__box"
    >
      <div className="header-drawer__box-logo">
        <span
          onClick={() => {
            navigate("/");
            setOpen(false);
          }}
        >
          <VoiiiceLogo />
        </span>
      </div>
      <Divider
        style={{
          borderColor: "#ffffff",
        }}
      />
      {login ? (
        <List>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/project/all-recordings");
              setOpen(false);
            }}
          >
            <ListItemButton className="header-drawer__box-button">
              <ListItemText primary={"Recordings"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/account/settings");
              setOpen(false);
            }}
          >
            <ListItemButton className="header-drawer__box-button">
              <ListItemText primary={"Account"} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              setLogin(false);
              setOpen(false);
            }}
          >
            <ListItemButton className="header-drawer__box-button">
              <ListItemText primary={"Logout"} onClick={() => logout()} />
            </ListItemButton>
          </ListItem>
        </List>
      ) : (
        <>
          <List>
            {[{ text: "Pricing", link: "/pricing" }].map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                onClick={() => {
                  navigate(item.link);
                  setOpen(false);
                }}
              >
                <ListItemButton className="header-drawer__box-button">
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider
            style={{
              borderColor: "#ffffff",
            }}
          />
          <List>
            {[
              { text: "Log in", link: "/auth/login" },
              { text: "Sign up", link: "/auth/signup" },
            ].map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                onClick={() => {
                  navigate(item.link);
                  setOpen(false);
                }}
              >
                <ListItemButton className="header-drawer__box-button">
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );

  return (
    <nav className="header">
      {width > 800 ? (
        <>
          <div className="header-brand" onClick={() => navigate("/")}>
            <div className="header-logo">
              <VoiiiceLogo />
            </div>
          </div>
          {login ? (
            <div className="header-logged-in">
              <Link to="/project/all-recordings">
                <HeadphoneIcon />
                &nbsp;Recordings
              </Link>
              <Link to="/account/settings">
                <PersonOutlineOutlinedIcon />
                &nbsp;Account
              </Link>
              <p onClick={() => logout()}>
                <LogoutOutlinedIcon />
                &nbsp;Logout
              </p>
            </div>
          ) : (
            <div className="header-actions">
              <div>
                <Link to="/pricing">Pricing</Link>
              </div>
              <div>
                <ButtonComponent
                  text="Log in"
                  path="/auth/login"
                  type="outlined"
                />
                <span className="header-actions--span" />
                <ButtonComponent
                  text="Sign up"
                  path="/auth/signup"
                  type="filled"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="header-brand">
            <div onClick={() => navigate("/")}>
              <VoiiiceLogo />
            </div>
            <MenuIcon onClick={() => setOpen(true)} />
          </div>
          <div>
            <Drawer
              anchor={"left"}
              open={open}
              onClose={() => setOpen(false)}
              className="header-small-drawer"
            >
              <NavList />
            </Drawer>
          </div>
        </>
      )}
    </nav>
  );
};

export default HeaderComponent;
