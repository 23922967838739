export const recData = {
  allRecs: [
    // {
    //   id: 1,
    //   proj_id: 1,
    //   title: "Radcliff Jr. Vol 2 Final version",
    //   project: "My first project",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 2,
    //   proj_id: 2,
    //   title: "Meditation from Thais - Violin and Piano",
    //   project: "Another project",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/massenet-thais-meditation-violin-piano.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 3,
    //   proj_id: 2,
    //   title: "Meditation from Thais - Cello and Piano",
    //   project: "Another project",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/massenet-thais-meditation-cello-piano.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 4,
    //   proj_id: 3,
    //   title: "Meditation from Thais - Piano Solo",
    //   project: "Project 3",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/edward-elgar-salut-d-amour-violin-piano.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 5,
    //   proj_id: 1,
    //   title: "Canon in D for Violin & Piano",
    //   project: "My first project",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/pachelbel-canon-in-d-violin-piano.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 6,
    //   proj_id: 1,
    //   title: "Canon in D arranged for Piano",
    //   project: "My first project",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/pachelbels-canon-arranged.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 7,
    //   proj_id: 3,
    //   title: "Canon in D for 4 SATB Recorders",
    //   project: "Project 3",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/pachelbels-canon-SATB-recorders.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 8,
    //   proj_id: 3,
    //   title: "La Caroline - a character piece",
    //   project: "Project 3",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/cpe-bach-la-caroline.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 9,
    //   proj_id: 1,
    //   title: "Keyboard Sonata in Fm, 1st movement - Allegro di Molto",
    //   project: "My first project",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/cpe-bach-sonata6-1-f-minor-allegro-di-molto.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
    // {
    //   id: 10,
    //   proj_id: 2,
    //   title: "March - no.16 from the A. M. Bach Notebook",
    //   project: "Another project",
    //   date: "April 5, 2022",
    //   url: "https://www.mfiles.co.uk/mp3-downloads/Notebook2-16-March.mp3",
    //   liked: false,
    //   playing: false,
    //   seek: 0,
    //   totalDuration: 0,
    //   playUrl: "",
    // },
  ],
  proj_urls: {
    // my_first_project: 1,
    // another_project: 1,
    // project_3: 1,
  },
  projects: [
    // {
    //   proj_id: 1,
    //   url: "my_first_project",
    //   name: "My first project",
    //   desc: "",
    //   img_src: "/bg.svg",
    //   msg_max_len: "3 min",
    //   proj_status: "locked",
    //   name_req: true,
    //   email_req: false,
    //   remove_watermark: false,
    //   embed_code:
    //     "Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsuma",
    //   show_img: true,
    //   border: false,
    //   border_color: "#fff",
    //   bg: true,
    //   bg_color: "#fff",
    //   text_color: "#141332",
    //   btn_color: "#3864FF",
    //   btn_text_color: "#FFFFFF",
    //   show_shadow: true,
    //   controls: "light",
    // },
    // {
    //   proj_id: 2,
    //   url: "another_project",
    //   name: "Another Project",
    //   desc: "",
    //   img_src: "/bg.svg",
    //   msg_max_len: "3 min",
    //   proj_status: "locked",
    //   name_req: true,
    //   email_req: false,
    //   remove_watermark: false,
    //   embed_code:
    //     "Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsuma",
    //   show_img: true,
    //   border: false,
    //   border_color: "#fff",
    //   bg: true,
    //   bg_color: "#fff",
    //   text_color: "#141332",
    //   btn_color: "#3864FF",
    //   btn_text_color: "#FFFFFF",
    //   show_shadow: true,
    //   controls: "light",
    // },
    // {
    //   proj_id: 3,
    //   url: "project_3",
    //   name: "Project 3",
    //   desc: "",
    //   img_src: "/bg.svg",
    //   msg_max_len: "3 min",
    //   proj_status: "locked",
    //   name_req: true,
    //   email_req: false,
    //   remove_watermark: false,
    //   embed_code:
    //     "Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsum html js Lorem upsuma",
    //   show_img: true,
    //   border: false,
    //   border_color: "#fff",
    //   bg: true,
    //   bg_color: "#fff",
    //   text_color: "#141332",
    //   btn_color: "#3864FF",
    //   btn_text_color: "#FFFFFF",
    //   show_shadow: true,
    //   controls: "light",
    // },
  ],
  users: [
    // {
    //   num: "01",
    //   name: "Steven Anderson",
    //   email: "stevenanderson@gmail.com",
    // },
    // {
    //   num: "02",
    //   name: "Adil Aijaz aldmas",
    //   email: "adilaijaz@gmail.com",
    // },
    // {
    //   num: "03",
    //   name: "Steven Canidsan",
    //   email: "steteklmasdasd@gmail.com",
    // },
    // {
    //   num: "04",
    //   name: "Steven Anderson",
    //   email: "stevenanderson@gmail.com",
    // },
    // {
    //   num: "05",
    //   name: "Steven Anderson",
    //   email: "stevenanderson@gmail.com",
    // },
    // {
    //   num: "06",
    //   name: "Steven Anderson",
    //   email: "stevenanderson@gmail.com",
    // },
    // {
    //   num: "07",
    //   name: "Steven Anderson",
    //   email: "stevenanderson@gmail.com",
    // },
    // {
    //   num: "08",
    //   name: "Steven Anderson",
    //   email: "stevenanderson@gmail.com",
    // },
  ],
};
