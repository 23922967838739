import ButtonComponent from "../components/button/button";
import "../styles/404.css";

const NotFoundComponent = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <p>The Page You are trying to request is not available at the moment</p>
      <div className="not-found__btns">
        <ButtonComponent path="/" text="Go Home" type="filled" />
        <ButtonComponent
          path="/pricing"
          text="Check out Pricing"
          type="outlined"
        />
      </div>
    </div>
  );
};

export default NotFoundComponent;
