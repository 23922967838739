import { useState } from "react";

import RecordWave from "./rec-wave/rec-wave";
import { ReactComponent as PlayGrayIcon } from "../../icons/svg/play-gray.svg";
import { ReactComponent as PlayDarkIcon } from "../../icons/svg/play-dark.svg";
import { ReactComponent as StopRedIcon } from "../../icons/svg/stop-red.svg";
import { ReactComponent as ForwardRecIcon } from "../../icons/svg/forward-rec.svg";
import { ReactComponent as ForwardRecDarkIcon } from "../../icons/svg/forward-rec-dark.svg";

const Recorder = ({ setRecState, setBlobObj, setFileObj, locObj }) => {
  const [micState, setMicState] = useState("start");

  return (
    <div className="wc-recorder">
      {micState === "stop" || micState === "pause" ? (
        <>
          <div className="wc-icons--align">
            {locObj.controls === "light" ? (
              <ForwardRecIcon
                className="wc-play"
                onClick={() => setMicState("resume")}
              />
            ) : (
              <ForwardRecDarkIcon
                className="wc-play"
                onClick={() => setMicState("resume")}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="wc-icons--align">
            {locObj.controls === "light" ? (
              <PlayGrayIcon
                className="wc-play"
                onClick={() => setMicState("pause")}
              />
            ) : (
              <PlayDarkIcon
                className="wc-play"
                onClick={() => setMicState("pause")}
              />
            )}
          </div>
        </>
      )}
      <div className="wc-rec-wave">
        <RecordWave
          micState={micState}
          setRecState={setRecState}
          setBlobObj={setBlobObj}
          setFileObj={setFileObj}
          setMicState={setMicState}
          locObj={locObj}
          timeAllowed={
            locObj.msg_max_len.split(" ")[1] === "sec"
              ? "00:30"
              : `0${locObj.msg_max_len.split(" ")[0]}:00`
          }
        />
      </div>
      <div className="wc-rec-right wc-icons--align">
        <StopRedIcon
          onClick={() => {
            setMicState("stop");
          }}
        />
      </div>
    </div>
  );
};

export default Recorder;
