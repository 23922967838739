const AccountHeading = ({ heading, children, margin = "16px 0", id = "" }) => {
  return (
    <>
      <h1
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 24,
          color: "#9B9B9B",
          marginBlock: 0,
          margin: margin,
        }}
      >
        {heading}
      </h1>
      <div>{children}</div>
    </>
  );
};

export default AccountHeading;
