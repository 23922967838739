import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ButtonComponent from "../button/button";
import "./pricing-table.css";
import { useCookies } from "react-cookie";
import { createSubscription } from "../../utils/createSession";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../providers/snackbar";

const PricingTable = ({
  type,
  proPrice,
  prePrice,
  staPrice,
  proId,
  preId,
  staId,
}) => {
  const [cookies] = useCookies(["token", "user"]);
  const [plan, setplan] = useState("free");
  const [planType, setPlanType] = useState("free");
  const { showSnackbar } = useContext(SnackBarContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (cookies?.user?.plan) {
      setplan(cookies.user.plan.plan_type);
      setPlanType(cookies.user.plan.type);
    }
  }, []);

  const handelSubs = (priceId, plan, type) => {
    if (cookies?.user)
      cookies?.user?.plan?.plan_type === "vip"
        ? showSnackbar({
            open: true,
            message: "You are a VIP",
            type: "success",
          })
        : createSubscription(priceId, plan, type, cookies?.token);
    else navigate("/auth/signup");
  };

  return (
    <div className="pricing-table">
      <div className="pricing-table__white-bg" />
      <div className="pricing-table__blue-bg" />
      <div className="pricing-table__vl" />
      <table>
        <tr>
          <th></th>
          <th>Starter</th>
          <th>Pro </th>
          <th>Premium</th>
        </tr>
        <tr>
          <th></th>
          <th>
            <span className="pricing-table__small">$</span>
            {staPrice}
            <span className="pricing-table__mo">
              /{type === "yr" ? "yr" : "mo"}
            </span>
          </th>
          <th>
            <span className="pricing-table__small">$</span>
            {proPrice}
            <span className="pricing-table__mo">
              /{type === "yr" ? "yr" : "mo"}
            </span>
          </th>
          <th>
            <span className="pricing-table__small">$</span>
            {prePrice}
            <span className="pricing-table__mo">
              /{type === "yr" ? "yr" : "mo"}
            </span>
          </th>
        </tr>
        <tr>
          <td>No. of recorded messages</td>
          <td>100</td>
          <td>1,000</td>
          <td>5,000</td>
        </tr>
        <tr>
          <td>Max recording duration allowed</td>
          <td>30 sec</td>
          <td>3 min</td>
          <td>10 min</td>
        </tr>
        <tr>
          <td>Max no. of podcasts</td>
          <td>1</td>
          <td>10</td>
          <td>unlimited</td>
        </tr>
        <tr>
          <td>Customize widget</td>
          <td></td>
          <td>
            <CheckCircleIcon
              style={{ color: "#3864FF", height: 28, width: 28 }}
            />
          </td>
          <td>
            <CheckCircleIcon
              style={{ color: "#DEF7FF", height: 28, width: 28 }}
            />
          </td>
        </tr>
        <tr>
          <td>Email audio</td>
          <td></td>
          <td>
            <CheckCircleIcon
              style={{ color: "#3864FF", height: 28, width: 28 }}
            />
          </td>
          <td>
            <CheckCircleIcon
              style={{ color: "#DEF7FF", height: 28, width: 28 }}
            />
          </td>
        </tr>
        <tr>
          <td>Remove Voiiice branding</td>
          <td></td>
          <td>
            <CheckCircleIcon
              style={{ color: "#3864FF", height: 28, width: 28 }}
            />
          </td>
          <td>
            <CheckCircleIcon
              style={{ color: "#DEF7FF", height: 28, width: 28 }}
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <ButtonComponent
              style={{
                background: "#3864FF",
                boxShadow: "0px 4px 12px rgba(56, 100, 255, 0.16)",
                borderRadius: 8,
                marginLeft: -50,
                transform: "translateX(25px)",
                textWrap: "wrap",
              }}
              text="Select"
              disabled={plan === "starter" && planType === type ? true : false}
              onClick={() => handelSubs(staId, "starter", type)}
            />
          </td>
          <td>
            <ButtonComponent
              style={{
                background: "#3864FF",
                boxShadow: "0px 4px 12px rgba(56, 100, 255, 0.16)",
                borderRadius: 8,
              }}
              text="Select"
              disabled={plan === "pro" && planType === type ? true : false}
              onClick={() => handelSubs(proId, "pro", type)}
            />
          </td>
          <td>
            <ButtonComponent
              style={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 12px rgba(0, 38, 173, 0.32)",
                borderRadius: 8,
                color: "#3864FF",
              }}
              disabled={plan === "premium" && planType === type ? true : false}
              text="Select"
              onClick={() => handelSubs(preId, "premium", type)}
            />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PricingTable;
