import { useContext, useState } from "react";

import { RecordContext } from "../../providers/records";

import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";

import "./sidebar-card.css";

function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
}

const SidebarCardComponent = () => {
  const [play, setPlay] = useState(false);
  const {
    currentPlaying,
    sideBarContainerIdPrfix,
    setPlaying,
    playNext,
    playPrev,
  } = useContext(RecordContext);

  return (
    <div className="sidebar-card">
      <div className="sidebar-card__voice">
        <div>
          {currentPlaying
            ? convertHMS(currentPlaying?.seek !== 0 ? currentPlaying?.seek : 0)
            : "..."}
        </div>
        <div
          className="sidebar-card__voice-audio"
          id={`${sideBarContainerIdPrfix}`}
        />
        <div>{currentPlaying ? currentPlaying.totalDuration : "..."}</div>
      </div>
      <div className="sidebar-card__card">
        <h1 className="sidebar-card__card-heading ">
          {/* {currentPlaying && currentPlaying.title ? ( */}
          {currentPlaying.title}
        </h1>
        <div className="sidebar-card__card-details ">
          <p className="sidebar-card__text">
            {currentPlaying && currentPlaying.project ? (
              currentPlaying.project
            ) : (
              <div
                style={{ width: 50, display: "flex", justifyContent: "center" }}
              >
                ...
              </div>
            )}
          </p>
          {/*<div></div>*/}
          <p>
            {currentPlaying && currentPlaying.date ? (
              currentPlaying.date
            ) : (
              <div
                style={{ width: 78, display: "flex", justifyContent: "center" }}
              >
                ...
              </div>
            )}
          </p>
          <p>
            {currentPlaying && currentPlaying.name ? (
              currentPlaying.name
            ) : (
              <div
                style={{ width: 78, display: "flex", justifyContent: "center" }}
              >
                ...
              </div>
            )}
          </p>
        </div>
        <div className="sidebar-card__card-controls">
          <div>
            <SkipPreviousRoundedIcon
              onClick={() =>
                playPrev(currentPlaying ? currentPlaying.id : null)
              }
            />
          </div>
          <div onClick={() => setPlay(!play)}>
            {currentPlaying && currentPlaying?.playing ? (
              <PauseCircleFilledRoundedIcon
                onClick={() =>
                  setPlaying(
                    currentPlaying ? currentPlaying.id : null,
                    false,
                    currentPlaying.liked
                  )
                }
              />
            ) : (
              <PlayCircleFilledRoundedIcon
                onClick={() =>
                  setPlaying(
                    currentPlaying ? currentPlaying.id : null,
                    true,
                    currentPlaying.liked
                  )
                }
              />
            )}
          </div>
          <div>
            <SkipNextRoundedIcon
              onClick={() =>
                playNext(currentPlaying ? currentPlaying.id : null)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarCardComponent;
