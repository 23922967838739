import { useState } from "react";

import "./accordian-block.css";
import AccordianStick from "./accordian-stick";

const AccordianBlock = () => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="accoridan-block">
      <AccordianStick
        expanded={expanded === "panel1"}
        handleChange={handleChange("panel1")}
        title={"The first question about voiiiice is who is?"}
        desc={"The first question about voiiiice is who is?"}
        id="panel1d-header"
      />
      <AccordianStick
        expanded={expanded === "panel2"}
        handleChange={handleChange("panel2")}
        title={"The first question about voiiiice is wh lkasmd alskdo is?"}
        desc={
          "We will sign influencers, invest in YouTube ads, Social Media Ads, PooCoin Banner Ads, as well as giveaways and other surprises."
        }
        id="panel2d-header"
      />
      <AccordianStick
        expanded={expanded === "panel3"}
        handleChange={handleChange("panel3")}
        title={"The first question about voiiiice?"}
        desc={"The first question about voiiiice?"}
        id="panel3d-header"
      />
      <AccordianStick
        expanded={expanded === "panel4"}
        handleChange={handleChange("panel4")}
        title={"How much does it cost?"}
        desc={"How much does it cost?"}
        id="panel4d-header"
      />
      <AccordianStick
        expanded={expanded === "panel5"}
        handleChange={handleChange("panel5")}
        title={
          "If I cancel my subcsription before expiry, will I be able to use it?"
        }
        desc={
          "If I cancel my subcsription before expiry, will I be able to use it?"
        }
        id="panel5d-header"
      />
    </div>
  );
};

export default AccordianBlock;
