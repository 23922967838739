import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import RecordContext from "./record-context";
import { SnackBarContext } from "../snackbar/index";

const RecordProvider = ({ children }) => {
  const containerIdPrfix = "audio__waves__container__";
  const sideBarContainerIdPrfix = "sidebar__audio__waves__container__";
  const [cookie, setCookie, removeCookie] = useCookies(["token", "user"]);
  const { showSnackbar } = useContext(SnackBarContext);

  const [value, setValue] = useState([]);
  const [liked, setLiked] = useState([]);
  const [allRecs, setAllRecs] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectIds, setProjectIds] = useState({});
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [currentPlayingOnSideBar, setCurrentPlayingOnSideBar] = useState(null);
  const [responseLoading, setResponseLoading] = useState(false);
  const [recLoading, setRecLoading] = useState(false);
  const navigate = useNavigate();

  const toggleLike = (id, likedStatus) => {
    let toLike = null;
    const newVal = value.map((val) => {
      if (val.id === id) val.liked = likedStatus;
      return val;
    });
    setValue(newVal);
    const changeAllVals = allRecs[0].map((val) => {
      if (val.id === id) {
        val.liked = likedStatus;
      }
      return val;
    });
    if (!likedStatus) {
      const changeLiked = liked.filter((li) => li.id !== id);
      const changeAllLiked = allRecs[1].filter((val) => val.id !== id);
      setLiked(changeLiked);
      setAllRecs([changeAllVals, changeAllLiked]);
    } else if (likedStatus) {
      value.map((val) => {
        if (val.id === id) toLike = val;
        return null;
      });
      toLike.liked = likedStatus;
      setLiked([...liked, toLike]);
      setAllRecs([changeAllVals, [...allRecs[1], toLike]]);
    }
    axios
      .post(
        "recording/like",
        { id: id, liked: likedStatus },
        {
          headers: {
            token: cookie?.token,
          },
        }
      )
      .then(() => {
        showSnackbar({
          open: true,
          message: likedStatus ? "Like Added!" : "Like Removed",
          type: "success",
        });
      })
      .catch((err) => {
        showSnackbar({
          open: true,
          message:
            err.response?.data?.error ||
            err.response?.data?.message ||
            "Failed to update like!",
          type: err.response.data.status || "error",
        });
      });
  };

  const setPlaying = (id, playBool = null, likedBool) => {
    if (id === null) return;
    if (likedBool) {
      const like = liked.map((v) => {
        if (v.playing && v.id !== id) {
          v.playing = false;
        } else if (v.id === id) {
          if (playBool) v.playing = playBool;
          else v.playing = !v.playing;
        }
        return v;
      });
      const vals = value.map((v) => {
        if (v.playing && v.id !== id) {
          v.playing = false;
        } else if (v.id === id) {
          if (playBool) v.playing = playBool;
          else v.playing = !v.playing;
        }
        return v;
      });
      setLiked(like);
      setValue(vals);
      value.map((v) => {
        if (v.playing) {
          setCurrentPlaying(v);
        }
        return {};
      });
    } else {
      const play = value.map((v) => {
        if (v.playing && v.id !== id) {
          v.playing = false;
        } else if (v.id === id) {
          if (playBool) v.playing = playBool;
          else v.playing = !v.playing;
        }
        return v;
      });
      setValue(() => play);
      value.map((v) => {
        if (v.playing) {
          setCurrentPlaying(v);
        }
        return {};
      });
    }
  };

  const stopAll = () => {
    setCurrentPlaying(null);
    const newValue = value.map((v) => {
      v.playing = false;
      return v;
    });
    const newLiked = liked.map((v) => {
      v.playing = false;
      return v;
    });
    setValue(newValue);
    setLiked(newLiked);
  };

  const playPrev = (id) => {
    if (id === null) return;
    let ind;
    value.map((v, index) => {
      if (index < value.length) {
        if (v.id === id) {
          v.playing = false;
          ind = index;
        }
        return v;
      } else {
        return v;
      }
    });
    if (ind !== 0)
      value.map((v, index) => {
        if (index === ind - 1) {
          v.playing = true;
          setCurrentPlaying(v);
        }
        return v;
      });
  };

  const playNext = (id) => {
    if (id === null) return;
    let come = false;
    value.map((v, index) => {
      if (index < value.length) {
        if (v.id === id && !come) {
          v.playing = false;
          come = true;
        } else if (come) {
          v.playing = true;
          come = false;
          setCurrentPlaying(v);
        }
        return v;
      } else {
        return v;
      }
    });
  };

  const setSeek = (id, seekDur) => {
    const seek = value.map((s) => {
      if (s.id === id) {
        s.seek = seekDur;
      }
      return s;
    });
    const seekLiked = liked.map((s) => {
      if (s.id === id) {
        s.seek = seekDur;
      }
      return s;
    });
    setValue(seek);
    setLiked(seekLiked);
    // setAllRecs(seek, seekLiked);
  };

  const setCompDuration = (id, dur) => {
    const seek = value.map((s) => {
      if (s.id === id) {
        s.totalDuration = dur;
      }
      return s;
    });
    const liSeek = liked.map((s) => {
      if (s.id === id) {
        s.totalDuration = dur;
      }
      return s;
    });
    setValue(seek);
    setLiked(liSeek);
    // setAllRecs([seek, liSeek]);
  };

  const searchRec = (search = "") => {
    if (search === "") {
      setValue(allRecs[0]);
      setLiked(allRecs[1]);
    } else {
      const searchVals = allRecs[0].filter((val) =>
        val.title.toLowerCase().includes(search.toLowerCase())
      );
      const searchLiked = allRecs[1].filter((li) =>
        li.title.toLowerCase().includes(search.toLowerCase())
      );
      setValue(searchVals);
      setLiked(searchLiked);
    }
  };

  const deleteRecording = (rec_id, setDestroy) => {
    axios
      .delete(`recording/delete/${rec_id}`, {
        headers: {
          token: cookie?.token,
        },
      })
      .then((_) => {
        setDestroy(true);
        getUserRecordings(cookie?.user?.id);
        showSnackbar({
          open: true,
          message: "Recording deleted successfully!",
          type: "success",
        });
      })
      .catch(async (err) => {
        if (err.response.status === 401) {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Unauthorized User!",
            type: "error",
          });
          await logout(false);
          showSnackbar({
            open: true,
            message: "Unauthorized User!",
            type: "error",
          });
        } else {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Failed to delete the recording!",
            type: err.response.data.status || "error",
          });
        }
      });
  };

  const getUserRecordings = (userId = null, ret = false) => {
    const user = cookie?.user?.id;

    setRecLoading(true);
    axios
      .get(`recording/user-recordings/${userId !== null ? userId : user}`, {
        headers: {
          token: cookie?.token,
        },
      })
      .then(({ data }) => {
        const users = [];
        const liks = [];
        const vals = [];
        data.data?.filter((res) => {
          if(!res?.isDeleted){
            vals.push({
              ...res,
              id: res.id,
              liked: res.liked === "true",
              seek: 0,
            });
            if (res.liked === "true")
              liks.push({
                ...res,
                id: res.id,
                liked: res.liked === "true",
                seek: 0,
              });
          }

          if (res.name || res.email)
            users.push({
              name: res?.name ? res?.name : "",
              email: res?.email ? res?.email : "",
            });
          return null;
        });
        if (ret) return { vals, liks };
        else {
          setValue(vals);
          setLiked(liks);
        }
        setAllRecs([vals, liks]);
        setUsers(users);
        setAllUsers(users);
        setRecLoading(false);
      })
      .catch(async (err) => {
        setRecLoading(false);
        if (err.response.status === 401) {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Unauthorized User!",
            type: "error",
          });
          await logout(false);
          showSnackbar({
            open: true,
            message: "Unauthorized User!",
            type: "error",
          });
        }
        // showSnackbar({
        //   open: true,
        //   message: err.response?.data?.error || err.response?.data?.message,
        //   type: "error",
        // });
      });
  };

  const getProject = (pro_id, recs = false) => {
    let project = null;
    let proj_id;
    let proj = [];
    let toLiked = [];
    projectList.map((p) => {
      if (p.id === pro_id) {
        proj_id = p.id;
        project = p;
      }
      return null;
    });
    if (recs) {
      if (project === null) {
        return { projectSettings: {}, projectRecs: [], projLiked: [] };
      } else {
        value.map((v) => {
          if (v.proj_id.id === proj_id) proj.push(v);
          return null;
        });
        liked.map((li) => {
          if (li.proj_id.id === proj_id) toLiked.push(li);
          return null;
        });
        return {
          projectSettings: project,
          projectRecs: proj,
          projLiked: toLiked,
        };
      }
    } else {
      return {
        projectSettings: project,
      };
    }
  };

  const getProjectName = (id) => {
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i].id === id) {
        return projectList[i].name;
      }
    }
  };

  const getProjectNamesAndIds = () => {
    const projs = [];

    projectList.map((proj) => {
      projs.push({ name: proj.name, id: proj.id });
      return null;
    });

    return projs;
  };

  const getUserProjects = (userId = null) => {
    const user = cookie?.user?.id;

    let idObj = Object();

    setProjectList([]);
    setProjectIds([]);
    axios
      .get(`project/user-projects/${userId !== null ? userId : user}`)
      .then(({ data }) => {
        const result = data.data;
        setProjectList(result);
        for (let i in result) {
          const obj = {};
          const id = result[i].id;
          obj[id] = 1;
          idObj = { ...idObj, ...obj };
        }
        setProjectIds(idObj);
      })
      .catch(async (err) => {
        if (err.response.status === 401) {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Unauthorized User!",
            type: "error",
          });
          await logout(false);
          showSnackbar({
            open: true,
            message: "Unauthorized User!",
            type: "error",
          });
        } else {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Failed to load projects!",
            type: err.response.data.status || "error",
          });
        }
      });
  };

  const saveOrUpdateProjects = (
    data,
    update = false,
    projectId = null,
    showAlert = false
  ) => {
    setResponseLoading(true);
    const token = cookie?.token;
    const user = cookie?.user?.id || cookie?.user?.id;
    const file = data.full_file;
    delete data.full_file;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(`${key}`, data[key]);
    });
    formData.append("image", file);
    formData.append("user", user);
    if (!update) {
      axios
        .post(`project/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        })
        .then(({ data }) => {
          setResponseLoading(false);
          const obj = Object();
          const id = data.data.id;
          obj[id] = 1;
          setProjectIds(() => ({ ...projectIds, ...obj }));
          setProjectList(() => [...projectList, data.data]);
          navigate(`project/${id}/setting#embeded-code`);
          showSnackbar({
            open: true,
            message: "Project Created!",
            type: "success",
          });
        })
        .catch((err) => {
          setResponseLoading(false);
          console.log(err);
          showSnackbar({
            open: true,
            message:
              err?.response?.data?.error ||
              err?.response?.data?.message ||
              "Failed to create the project!",
            type: err?.response?.data?.status || "error",
          });
        });
    } else if (update) {
      formData.append("id", projectId);
      axios
        .post(`project/update-project`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
          },
        })
        .then(({ data }) => {
          setResponseLoading(false);
          let projs = [];
          projectList.map((pro) => {
            if (pro.id !== projectId) {
              projs.push(pro);
            } else {
              projs.push(data.data);
            }
            return null;
          });
          setProjectList(projs);
          showSnackbar({
            open: true,
            message: "Project Updated!",
            type: "success",
          });
        })
        .catch((err) => {
          setResponseLoading(false);
          if (err.response.status === 401) {
            showSnackbar({
              open: true,
              message:
                err.response?.data?.error ||
                err.response?.data?.message ||
                "Unauthorized User!",
              type: "error",
            });
            removeCookie("token");
            removeCookie("user");
            navigate("/auth/login");
          } else {
            showSnackbar({
              open: true,
              message:
                err.response?.data?.error ||
                err.response?.data?.message ||
                "Failed to update the project!",
              type: err.response.data.status || "error",
            });
          }
        });
    }
  };

  const idExist = (id) => {
    return projectIds[id];
  };

  const deleteProject = (proj_id) => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    const token = cookie?.token;
    axios
      .delete(`project/delete/${proj_id}`, {
        headers: {
          token: token,
        },
      })
      .then((data) => {
        getUserProjects();
        navigate("project/all-recordings");
        showSnackbar({
          open: true,
          message: "Project Deleted!",
          type: "success",
        });
      })
      .catch(async (err) => {
        if (err.response.status === 401) {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Unauthorized User!",
            type: "error",
          });
          await logout(false);
          showSnackbar({
            open: true,
            message: "Unauthorized User!",
            type: "error",
          });
        } else {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Failed to delete the project!",
            type: err.response.data.status || "error",
          });
        }
      });
  };

  const deleteBulkRecording = (rec_ids, setBulkDelete, setEdit) => {
    const data = { ids: rec_ids };
    axios
      .post("recording/deleteMany", data, {
        headers: {
          token: cookie?.token,
        },
      })
      .then((_) => {
        getUserRecordings(cookie?.user?.id);
        setBulkDelete([]);
        setEdit(false);
        showSnackbar({
          open: true,
          message: "Recordings deleted successfully!",
          type: "success",
        });
      })
      .catch(async (err) => {
        if (err.response.status === 401) {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Unauthorized User!",
            type: "error",
          });
          await logout(false);
          showSnackbar({
            open: true,
            message: "Unauthorized User!",
            type: "error",
          });
        } else {
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Failed to delete the recordings!",
            type: err.response.data.status || "error",
          });
        }
      });
  };

  const searchUser = (search = "") => {
    if (search === "") {
      setUsers(allUsers);
    } else {
      const s = [];
      allUsers.map((sear) => {
        if (
          sear.name.toLowerCase().includes(search.toLowerCase()) ||
          sear.email.toLowerCase().includes(search.toLowerCase())
        )
          s.push(sear);
        return null;
      });
      setUsers(s);
    }
  };

  const logout = async (showRes = true) => {
    removeCookie("token", { path: "/" });
    removeCookie("user", { path: "/" });
    setValue([]);
    setLiked([]);
    setAllRecs([]);
    setProjectList([]);
    setProjectIds({});
    setUsers([]);
    setAllUsers([]);
    setCurrentPlaying(null);
    setCurrentPlayingOnSideBar(null);
    setResponseLoading(false);
    setRecLoading(false);
    navigate("/auth/login");
    showRes &&
      showSnackbar({
        open: true,
        message: "Logged Out Successfully",
        type: "success",
      });
  };

  const removeDuplicates = (data) =>{
   return data.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.email === item.email
        ))
    )
  }


  const getUserDetails = () => {
    const userDetails = users.map((user) => [user.name, user.email]);
    return [["username", "email"], ...userDetails];
  };

  const handleSubscriptionUpgrade = (tab, token, price = 0) => {
    setRecLoading(true);
    if (tab === "starter") {
      axios
        .put(
          `auth/update-user/${cookie?.user?.id}`,
          {
            plan: {
              plan_type: "starter",
              fee: 0,
              start_date: new Date(),
            },
          },
          {
            headers: {
              token: cookie?.token,
            },
          }
        )
        .then(async (response) => {
          console.log(response);
          await Promise.all(
            Object.keys(projectIds).map((key) => {
              const formData = new FormData();
              formData.append("id", key);
              formData.append("msg_max_len", "30 sec");
              return axios.post(`project/update-project`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  token: cookie?.token,
                },
              });
            })
          );
          getUserProjects();
          setRecLoading(false);
          setCookie("user", {
            ...cookie?.user,
            plan: { ...cookie?.user?.plan, plan_type: "starter" },
          });
          showSnackbar({
            open: true,
            message: "Subscription downgraded to starter successfully!",
            type: "success",
          });
        })
        .catch((err) => {
          setRecLoading(false);
          console.log(err);
          showSnackbar({
            open: true,
            message: "Subscription upgrade failed!",
            type: err.response.data.status || "error",
          });
        });
    } else if (tab === "pro" || tab === "premium") {
      let amount = price;
      axios
        .post(
          "/payment/pay",
          { amount: amount, plan_type: tab, token: token.id },
          {
            headers: {
              token: cookie?.token,
            },
          }
        )
        .then(async (response) => {
          console.log(response);
          if (tab === "pro") {
            await Promise.all(
              projectList.map((proj) => {
                let projMaxLen =
                  proj.msg_max_len.split(" ")[1] !== "sec" &&
                  parseInt(proj.msg_max_len.split(" ")[0]) > 3
                    ? "3 min"
                    : proj.msg_max_len;
                const formData = new FormData();
                formData.append("id", proj.id);
                formData.append("msg_max_len", projMaxLen);
                return axios.post(`project/update-project`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    token: cookie?.token,
                  },
                });
              })
            );
            getUserProjects();
          }
          setRecLoading(false);
          setCookie("user", {
            ...cookie?.user,
            plan: { ...cookie?.user?.plan, plan_type: tab },
          });
          showSnackbar({
            open: true,
            message: "Subscription upgraded successfully!",
            type: "success",
          });
        })
        .catch((err) => {
          setRecLoading(false);
          console.log(err);
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Subscription upgrade failed!",
            type: err.response.data.status || "error",
          });
        });
    }
  };

  return (
    <RecordContext.Provider
      value={{
        getUserProjects,
        handleSubscriptionUpgrade,
        getUserDetails,
        containerIdPrfix,
        sideBarContainerIdPrfix,
        value,
        liked,
        toggleLike,
        setPlaying,
        setSeek,
        currentPlaying,
        setCompDuration,
        searchRec,
        getProject,
        getProjectNamesAndIds,
        saveOrUpdateProjects,
        idExist,
        deleteProject,
        deleteRecording,
        playNext,
        playPrev,
        getProjectName,
        currentPlayingOnSideBar,
        setCurrentPlayingOnSideBar,
        users,
        searchUser,
        responseLoading,
        getUserRecordings,
        recLoading,
        logout,
        deleteBulkRecording,
        stopAll,
        projectList,
        setRecLoading,
        removeDuplicates
      }}
    >
      {children}
    </RecordContext.Provider>
  );
};

export default RecordProvider;
