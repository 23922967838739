import { useNavigate } from "react-router-dom";

import "./button.css";

const ButtonComponent = ({
  path = "",
  type,
  text,
  onClick = () => {},
  submit = false,
  style = {},
  disabled = false,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {type === "outlined" ? (
        <button
          onClick={path !== "" ? () => navigate(`${path}`) : onClick}
          className={`button-style button--outlined`}
          type={submit ? "submit" : "button"}
          style={style}
          disabled={disabled}
        >
          {text}
        </button>
      ) : (
        <button
          onClick={path !== "" ? () => navigate(`${path}`) : onClick}
          className="button-style button--filled"
          type={submit ? "submit" : "button"}
          style={style}
          disabled={disabled}
        >
          {text}
        </button>
      )}
    </>
  );
};

export default ButtonComponent;
