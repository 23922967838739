import { forwardRef, useEffect, useState } from "react";
import { useContext } from "react";
import { useCookies } from "react-cookie";
import FavoriteIcon from "@mui/icons-material/Favorite";

import RecordContext from "../../providers/records/record-context";

import "./tab-navs.css";

const TabNavsComponent = forwardRef((props, ref) => {
  const { setTabs, tabs, type, ...otherProps } = props;
  const [cookies] = useCookies(["user"]);
  const [disable, setDisable] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [themeDisable, setThemeDisable] = useState(false);
  const { stopAll } = useContext(RecordContext);
  useEffect(() => {
    if (cookies?.user?.plan?.plan_type === "vip") setDisable(true);
    if (
      (cookies?.user?.plan?.plan_type === "free" ||
      cookies?.user?.plan?.plan_type === "trial") && cookies?.user.trial_status === true
    )
    {
      setThemeDisable(true);
    }

    if (cookies?.user?.plan?.plan_type === "free" && cookies?.user.trial_status === true)
    {
      setDisableButton(true);
    }
  }, []);

  return (
    <>
      {type === "live" || type === "month" ? (
        <div className="tab-navs" {...otherProps} ref={ref}>
          <button
              disabled={disableButton}
            onClick={() => setTabs(type === "month" ? "monthly" : "live")}
            className={`${
              tabs === "" || tabs === "live" || tabs === "monthly"
                ? "tab-nav--active"
                : ""
            }`}
          >
            {type === "month" ? "Monthly" : "Live"}
          </button>
          <button
              disabled={disableButton}
            onClick={() => setTabs(type === "month" ? "annually" : "disabled")}
            className={`${
              tabs === "disabled" || tabs === "annually"
                ? "tab-nav--active"
                : ""
            }`}
          >
            {type === "month" ? "Annually" : "Disabled"}
          </button>
        </div>
      ) : type === "all" ? (
        <div className="tab-navs" {...otherProps}>
          <button
            onClick={() => {
              setTabs("all");
              stopAll();
            }}
            className={`${
              tabs === "" || tabs === "all" ? "tab-nav--active" : ""
            }`}
          >
            All
          </button>
          <button
            onClick={() => {
              setTabs("heart");
              stopAll();
            }}
            className={`${tabs === "heart" ? "tab-nav--active" : ""}`}
          >
            <FavoriteIcon className="tab-nav--heart" />
          </button>
        </div>
      ) : type === "min" ? (
        <div className="tab-navs" {...otherProps}>
          <button
            onClick={() => setTabs("30 sec")}
            className={`${
              tabs === "" || tabs === "30 sec" ? "tab-nav--active" : ""
            }`}
          >
            30 sec
          </button>
          {(cookies?.user.plan.plan_type === "premium" ||
            cookies?.user?.plan.plan_type === "vip" ||
            cookies?.user.plan.plan_type === "pro") && (
            <>
              <button
                onClick={() => setTabs("1 min")}
                className={`${tabs === "1 min" ? "tab-nav--active" : ""}`}
              >
                1 min
              </button>
              <button
                onClick={() => setTabs("2 min")}
                className={`${tabs === "2 min" ? "tab-nav--active" : ""}`}
              >
                2 min
              </button>
              <button
                onClick={() => setTabs("3 min")}
                className={`${tabs === "3 min" ? "tab-nav--active" : ""}`}
              >
                3 min
              </button>
            </>
          )}
          {(cookies?.user.plan.plan_type === "premium" ||
            cookies?.user?.plan.plan_type === "vip") && (
              <>
                <button
                  onClick={() => setTabs("4 min")}
                  className={`${tabs === "4 min" ? "tab-nav--active" : ""}`}
                >
                  4 min
                </button>
                <button
                  onClick={() => setTabs("6 min")}
                  className={`${tabs === "6 min" ? "tab-nav--active" : ""}`}
                >
                  6 min
                </button>
                <button
                  onClick={() => setTabs("8 min")}
                  className={`${tabs === "8 min" ? "tab-nav--active" : ""}`}
                >
                  8 min
                </button>
                <button
                  onClick={() => setTabs("10 min")}
                  className={`${tabs === "10 min" ? "tab-nav--active" : ""}`}
                >
                  10 min
                </button>
              </>
            )}
        </div>
      ) : type === "light" ? (
        <div className="tab-navs" {...otherProps}>
          <button
            onClick={() => setTabs("light")}
            className={`${
              tabs === "" || tabs === "light" ? "tab-nav--active" : ""
            }`}
          >
            Light
          </button>
          <button
            disabled={themeDisable}
            onClick={() => {
              setTabs("dark");
            }}
            className={`${tabs === "dark" ? "tab-nav--active" : ""}`}
          >
            Dark
          </button>
        </div>
      ) : (
        <div className="tab-navs" {...otherProps}>
          {(cookies?.user.plan.plan_type === "free" &&
            cookies?.user.trial_status === false) ||
          cookies?.user.plan.plan_type === "trial" ? (
            <button
              disabled={disable}
              onClick={() => setTabs("trial")}
              className={`${
                tabs === "" || tabs === "trial" ? "tab-nav--active" : ""
              }`}
            >
              Trial
            </button>
          ) : (
            <></>
          )}
          <button
            disabled={disable}
            onClick={() => setTabs("starter")}
            className={`${
              tabs === "" || tabs === "starter" ? "tab-nav--active" : ""
            }`}
          >
            Starter
          </button>
          <button
            onClick={() => setTabs("pro")}
            disabled={disable}
            className={`${tabs === "pro" ? "tab-nav--active" : ""}`}
          >
            Pro
          </button>
          <button
            disabled={disable}
            onClick={() => setTabs("premium")}
            className={`${tabs === "premium" ? "tab-nav--active" : ""}`}
          >
            Premium
          </button>
        </div>
      )}
    </>
  );
});

export default TabNavsComponent;
