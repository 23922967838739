import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useContext } from "react";
import axios from "axios";

import RecordContext from "../../../providers/records/record-context";
import ButtonComponent from "../../../components/button/button";
import TabNavsComponent from "../../../components/tab-navs/tab-navs";
import AccountHeading from "../account-heading";
import Popup from "../../../components/popup/popup";
import { useBlocker } from "../../../utils/useBlocker";

import "./index.css";
import { SnackBarContext } from "../../../providers/snackbar";
import { checkTrialDays } from "../../../utils/createSession";
import { prices } from "../../../utils/const";


const SubscriptionSection = () => {
  const [tabs, setTabs] = useState("");
  const [plan, setPlan] = useState("");

  const { recLoading, projectList, getUserProjects, setRecLoading } =
    useContext(RecordContext);

  const [cookies, setCookies] = useCookies(["token", "user"]);
  const navigate = useNavigate();
  const { showSnackbar } = useContext(SnackBarContext);

  const location = useLocation();
  const [openPopup, setOpenPopup] = useState(false);

  const handelUnsubscribe = async () => {
    if (cookies?.user?.plan?.auto_renew) {
      try {
        const res = await axios.put(
          "/subscription",
          {},
          {
            headers: {
              token: cookies?.token,
            },
          }
        );

        const expires = new Date();
        expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
        setCookies("user", res?.data, {
          path: "/",
          expires: expires,
        });
        showSnackbar({
          open: true,
          message: "Your subscriptions auto renew is successfully canceled",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        showSnackbar({
          open: true,
          message: "Something went wrong not able to unsubscribed",
          type: "error",
        });
      }
    } else {
      showSnackbar({
        open: true,
        message: "Your auto renewal on this subscription is already disabled",
        type: "error",
      });
    }
  };
  const formatEndDate = (endDate) => {
    if (endDate === "NaN") {
      const startDate = new Date(cookies.user.plan.start_date);
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate()
      );
      const formattedEndDate = endDate.toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return formattedEndDate;
    }
    return endDate;
  };

  useEffect(() => {
    setTabs(cookies?.user?.plan?.plan_type);
    setPlan(cookies?.user?.plan?.plan_type);
  }, [cookies.user?.plan?.plan_type]);

  useEffect(() => {
    if (
      location.search === "?membership=pro" ||
      location.search === "?membership=premium"
    ) {
      setTabs(location?.search?.split("?")[1]?.split("=")[1]);
      const subsScroll = document?.getElementById("subs-scroll");

      subsScroll?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  useEffect(() => {
    (async () => {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);

      if (query.get("success")) {
        const tab = query.get("subscribedPlan");

        if (tab === "starter") {
          await Promise.all(
            projectList.map((proj) => {
              let projMaxLen =
                proj.msg_max_len.split(" ")[1] !== "sec" &&
                parseInt(proj.msg_max_len.split(" ")[0]) > 3
                  ? "3 sec"
                  : proj.msg_max_len;
              const formData = new FormData();
              formData.append("id", proj.id);
              formData.append("msg_max_len", projMaxLen);
              return axios.post(`project/update-project`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  token: cookies?.token,
                },
              });
            })
          );
          getUserProjects();
        } else if (tab === "pro") {
          await Promise.all(
            projectList.map((proj) => {
              let projMaxLen =
                proj.msg_max_len.split(" ")[1] !== "sec" &&
                parseInt(proj.msg_max_len.split(" ")[0]) > 5
                  ? "5 min"
                  : proj.msg_max_len;
              const formData = new FormData();
              formData.append("id", proj.id);
              formData.append("msg_max_len", projMaxLen);
              return axios.post(`project/update-project`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  token: cookies?.token,
                },
              });
            })
          );
          getUserProjects();
        } else if (tab === "premium") {
          await Promise.all(
            projectList.map((proj) => {
              let projMaxLen =
                proj.msg_max_len.split(" ")[1] !== "sec" &&
                parseInt(proj.msg_max_len.split(" ")[0]) > 10
                  ? "10 min"
                  : proj.msg_max_len;
              const formData = new FormData();
              formData.append("id", proj.id);
              formData.append("msg_max_len", projMaxLen);

              return axios.post(`project/update-project`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  token: cookies?.token,
                },
              });
            })
          );
          getUserProjects();
        }
        setRecLoading(false);

        const res = await axios.get("auth", {
          headers: {
            token: cookies?.token,
          },
        });

        const expires = new Date();
        expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
        setCookies("user", res?.data, {
          path: "/",
          expires: expires,
        });
        showSnackbar({
          open: true,
          message: "Subscription upgraded successfully!",
          type: "success",
        });
      }

      if (query.get("canceled")) {
      }
    })();
  }, []);

  const handleNavigation = () => {
    alert("Processing payment, please wait!");
  };

  useBlocker(handleNavigation, recLoading);

  return (
    <div className="subscription-section">
      <AccountHeading
        margin="44px 0 0 0"
        heading="Subscription"
        children={
          <>
            <div className="subscription-section__info">
              {cookies?.user.plan.plan_type === "trial" ? (
                <span>
                  Your trial expires
                  {` ${checkTrialDays(cookies?.user.plan.end_date)} `}
                </span>
              ) : cookies?.user.plan.plan_type === "free" &&
                cookies?.user.trial_status === false ? (
                <div>
                  <Link
                    to={"/pricing?trial=true"}
                    style={{ marginTop: 0 }}
                    class="subscription-section__content-trial"
                  >
                    Start your 30-day trial now!
                  </Link>
                </div>
              ) : cookies?.user.plan.plan_type === "vip" ? (
                <></>
              ) : cookies?.user.plan.plan_type !== "free" ? (
                <span>
                  Your subscription type is{" "}
                  {cookies?.user?.plan.type === "yr"
                    ? "Annual"
                    : cookies?.user?.plan.type === "mo"
                    ? "Monthly"
                    : ` ${cookies?.user?.plan.type} `}
                  {cookies?.user.plan.auto_renew && " and auto-renews on"}
                  {cookies?.user.plan.auto_renew &&
                    ` ${formatEndDate(cookies?.user.plan.end_date)}`}
                </span>
              ) : (
                <></>
              )}
              {cookies?.user.plan.plan_type === "free" &&
                cookies?.user.trial_status === true ?
                  <div style={{color: "orange"}}>
                You Free trial has expired
              </div> :null}

            </div>
            {!recLoading ? (
              <div className="subscription-section__content">
                <div className="subscription-section__content-tiers">
                  <span>Select tier:</span>
                  <div>
                    <TabNavsComponent
                      type="starter"
                      tabs={tabs}
                      setTabs={setTabs}
                    />
                  </div>
                  {cookies?.user.plan.plan_type === "vip" ? (
                    <span style={{ paddingLeft: 10 }}>🌟 You are a VIP</span>
                  ) : (
                    <></>
                  )}
                </div>

                {tabs === plan ? (
                  <div className="subscription-section__unsubscribe">
                    <Link
                      className="subscription-section__content-pricing"
                      to="/pricing"
                    >
                      Learn more about pricing
                    </Link>

                    {plan === "starter" ||
                    plan === "pro" ||
                    plan === "premium" ? (
                      <div>
                        <ButtonComponent
                          type="filled"
                          text={"Cancel Subscription"}
                          onClick={() => handelUnsubscribe()}
                          disabled={!cookies?.user?.plan?.auto_renew}
                        />
                        {cookies?.user?.plan?.auto_renew ? (
                          <div>
                            Canceling will stop the auto renewal of the current
                            plan. You can still enjoy your current period until
                            expiration.
                          </div>
                        ) : (
                          <div>
                            You are currently enrolled in {plan} until{" "}
                            <span>
                              {formatEndDate(cookies.user.plan.end_date)}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : (plan === "pro" && tabs === "starter") ||
                  plan === "premium" ? (
                  <div className="subscription-section__content-selection">
                    <div style={{ textTransform: "capitalize" }}>
                      Selected Plan:&nbsp;&nbsp;{tabs}
                    </div>
                    <div>
                      <ButtonComponent
                        text="Downgrade Subscription"
                        type="filled"
                        onClick={() => setOpenPopup(true)}
                      />
                    </div>
                  </div>
                ) : (plan === "pro" && tabs === "premium") ||
                  plan === "starter" ? (
                  <div className="subscription-section__content-selection">
                    <div style={{ textTransform: "capitalize" }}>
                      Selected Plan:&nbsp;&nbsp;{tabs}
                    </div>
                    <div className="subscription-section__content-selection--action">
                      <ButtonComponent
                        text="Upgrade Subscription"
                        type="filled"
                        onClick={() => setOpenPopup(true)}
                      />
                    </div>
                  </div>
                ) : plan === "free" &&
                  cookies?.user.trial_status === false &&
                  tabs === "trial" ? (
                  <div className="subscription-section__unsubscribe">
                    <div style={{ textTransform: "capitalize" }}>
                      Selected Plan:&nbsp;&nbsp;{tabs}
                    </div>
                    <div>
                      <ButtonComponent
                        type="filled"
                        text="Start free Trial"
                        onClick={() => navigate("/pricing?trial=true")}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="subscription-section__content-selection">
                    <div style={{ textTransform: "capitalize" }}>
                      Selected Plan:&nbsp;&nbsp;{tabs}
                    </div>
                    <div className="subscription-section__content-selection--action">
                      <ButtonComponent
                        text="Subscribe"
                        type="filled"
                        onClick={() => setOpenPopup(true)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
                <div style={{ marginLeft: 10, color: "#9b9b9b" }}>
                  Waiting for subscription...
                </div>
              </div>
            )}
          </>
        }
      />
      {openPopup && (
        <Popup
          setOpenPopup={setOpenPopup}
          pricing={
            tabs === "starter"
              ? prices.starter
              : tabs === "pro"
              ? prices.pro
              : prices.premium
          }
          tabs={tabs}
          plan={plan}
        />
      )}
    </div>
  );
};

export default SubscriptionSection;
