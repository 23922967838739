import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCookies } from "react-cookie";
import { SnackBarContext } from "../providers/snackbar/context";
import { RecordContext } from "../providers/records";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { gapi } from "gapi-script";
// import { CircularProgress } from "@mui/material";
import ButtonComponent from "../components/button/button";
// import IconButtonComponent from "../components/icon-button/icon-button";
// import GoogleIcon from "../icons/svg/google.svg";
// import FacebookIcon from "../icons/svg/facebook.svg";
import "../styles/auth.css";
import { Helmet } from "react-helmet";
const AuthPage = () => {
  const [loc, setLoc] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [vip, setVip] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies(["token", "user"]);
  const location = useLocation();
  const { getUserProjects } = useContext(RecordContext);
  const schema = loc
    ? yup
        .object({
          email: yup
            .string()
            .matches(
              /^[a-zA-Z0-9._%]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              "Invalid email"
            )
            .email("Please provide a valid email address!")
            .required("Email is required!"),
          password: yup
            .string()
            .min(6, "Password should have minimum 6 characters!")
            .required("Password is required!"),
          first_name: yup
            .string()
            .min(3, "Should have minimum 3 characters!")
            .required("First Name is required!"),
          last_name: yup
            .string()
            .min(3, "Should have minimum 3 characters!")
            .required("Last Name is required!"),
        })
        .required()
    : yup
        .object({
          email: yup
            .string()
            .matches(
              /^[a-zA-Z0-9._%]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              "Invalid email"
            )
            .email("Please provide a valid email address!")
            .required("Email is required!"),
          password: yup
            .string()
            .min(6, "Password should have minimum 6 characters!")
            .required("Password is required!"),
        })
        .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { showSnackbar } = useContext(SnackBarContext);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("vip")) {
      setVip(true);
    }
  }, []);

  const navigateToLogin = (data) => {
    if (
      location.search === "?membership=pro" ||
      location.search === "?membership=premium" ||
      location.search === "?membership=starter"
    ) {
      axios.post(`auth/login`, data).then((res) => {
        setAuthLoading(false);
        reset();
        getUserProjects(res.data?.data?.id);
        const expires = new Date();
        expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
        setCookie("token", res.data.token, {
          path: "/",
          expires: expires,
        });
        setCookie("user", res.data.data, {
          path: "/",
          expires: expires,
        });

        setAuthLoading(false);
        navigateToRecsOrAccount();
      }).catch((err) =>{
        console.log({err})
      });
    } else {
      setAuthLoading(false);
      navigate("/auth/login");
    }
  };

  const navigateToRecsOrAccount = () => {
    if (
      location.search === "?membership=pro" ||
      location.search === "?membership=premium"
    )
      navigate(`/account/settings${location.search}`);
    else navigate("/project/all-recordings");
  };

  // function googleAuth(user) {
  //   setAuthLoading(true);
  //   removeCookie("user");
  //   removeCookie("token");
  //   user.profileObj.password = user.profileObj.googleId;
  //   axios
  //     .post("auth/googleAuth", {
  //       data: user.profileObj,
  //     })
  //     .then((response) => {
  //       setAuthLoading(false);
  //       if (response.data.data && response.data.token) {
  //         const expires = new Date();
  //         expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
  //         setCookie("token", response.data.token, {
  //           path: "/",
  //           expires: expires,
  //         });
  //         setCookie("user", response.data.data, {
  //           path: "/",
  //           expires: expires,
  //         });
  //         getUserProjects(response.data.data.id);
  //         navigateToRecsOrAccount();
  //         showSnackbar({
  //           open: true,
  //           message: response.data.message || "User logged in successfully!",
  //           type: response.data.status || "success",
  //         });
  //       } else {
  //         showSnackbar({
  //           open: true,
  //           message: response.data.message || "User registered successfully!",
  //           type: response.data.status || "success",
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setAuthLoading(false);
  //       showSnackbar({
  //         open: true,
  //         message:
  //           err.response?.data?.error ||
  //           err.response?.data?.message ||
  //           "Authentication Failed",
  //         type: err.response.data.status || "error",
  //       });
  //     });
  // }

  // async function facebookAuth(user) {
  //   setAuthLoading(true);
  //   removeCookie("user");
  //   removeCookie("token");
  //   let text = user.name;
  //   const myArray = text.split(" ");
  //   user.givenName = myArray[0];
  //   user.familyName = myArray[1];
  //   user.facebookId = user.id;
  //   user.password = user.id;
  //   axios
  //     .post("auth/facebook-signup", {
  //       data: user,
  //     })
  //     .then((response) => {
  //       setAuthLoading(false);
  //       navigateToRecsOrAccount();
  //     })
  //     .catch((err) => {
  //       setAuthLoading(false);
  //       showSnackbar({
  //         open: true,
  //         message:
  //           err.response?.data?.error ||
  //           err.response?.data?.message ||
  //           "Authentication Failed",
  //         type: err.response.data.status || "error",
  //       });
  //     });
  // }

  const onSubmit = (data) => {
    setAuthLoading(true);
    removeCookie("user");
    removeCookie("token");
    if (loc) {
      if (vip) {
        data.plan_type = "vip";
      }
      axios
        .post("auth/sign-up", data)
        .then((res) => {
          reset();
          showSnackbar({
            open: true,
            message: "Account Created Successfully!",
            type: "success",
          });
          navigateToLogin(data);
        })
        .catch((err) => {
          setAuthLoading(false);
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Sign Up Failed",
            type: err.response.data.status || "error",
          });
        });
    } else {
      axios
        .post(`auth/login`, data)
        .then((res) => {
          setAuthLoading(false);
          reset();
          getUserProjects(res.data?.data?.id);
          const expires = new Date();
          expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
          setCookie("token", res.data.token, {
            path: "/",
            expires: expires,
          });
          setCookie("user", res.data.data, {
            path: "/",
            expires: expires,
          });

          navigateToRecsOrAccount();
          showSnackbar({
            open: true,
            message: "Logged In Successfully!",
            type: "success",
          });
        })
        .catch((err) => {
          setAuthLoading(false);
          showSnackbar({
            open: true,
            message:
              err.response?.data?.error ||
              err.response?.data?.message ||
              "Authentication Failed",
            type: err.response.data.status || "error",
          });
        });
    }
  };

  useEffect(() => {
    if (cookie?.token && cookie?.user) navigate("/project/all-recordings");
  }, []);

  useEffect(() => {
    function start() {
      gapi.auth2.init({
        client_id:
          "746577116259-adv48ebb83n2lmsk0b3911mtjhv7pmh9.apps.googleusercontent.com",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    reset();
    if (params.authId === "signup") setLoc(true);
    else setLoc(false);
  }, [params.authId]);

  return (
    <div className="auth">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="auth--align">
        <div className="auth-tab-menu">
          <div className="auth-tab-menu--btns">
            <div
              onClick={() => navigate("/auth/login")}
              className={`${loc ? "" : "auth-tab-menu--btn-active"}`}
            >
              Log in
            </div>
            <div
              onClick={() => navigate("/auth/signup")}
              className={`${loc ? "auth-tab-menu--btn-active" : ""}`}
            >
              Sign up
            </div>
          </div>
          <div className="auth-tab-menu--content">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="auth-tab-menu--content-form"
            >
              {loc && (
                <>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    id="sign-auth-first_name"
                    {...register("first_name")}
                  />
                  {errors.first_name && (
                    <p role="alert" className="auth-tab__error">
                      {errors.first_name?.message}
                    </p>
                  )}
                  <span style={{ height: 16 }} />
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    id="sign-auth-last_name"
                    {...register("last_name")}
                  />
                  {errors.last_name && (
                    <p role="alert" className="auth-tab__error">
                      {errors.last_name?.message}
                    </p>
                  )}
                  <span style={{ height: 16 }} />
                </>
              )}
              <input
                type="email"
                placeholder="Email"
                name="email"
                id="auth-email"
                {...register("email")}
                aria-invalid={errors.email ? "true" : "false"}
              />
              {errors.email && (
                <p role="alert" className="auth-tab__error">
                  {errors.email?.message}
                </p>
              )}
              <span style={{ height: 16 }} />
              <input
                type="password"
                placeholder="Password"
                name="password"
                id="auth-password"
                {...register("password")}
                aria-invalid={errors.password ? "true" : "false"}
              />
              {errors.password && (
                <p role="alert" className="auth-tab__error">
                  {errors.password?.message}
                </p>
              )}
              <span style={{ height: 24 }} />
              {!loc && (
                <>
                  <div
                    className="auth-tab__forget"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password ?
                  </div>
                  <span style={{ height: 24 }} />
                </>
              )}
              <ButtonComponent
                submit={true}
                text={loc ? "Sign up" : "Log in"}
                type="filled"
                disabled={authLoading}
              />
            </form>
            {/* <div className="auth-tab-menu--content-or">or</div> */}
            {/* Google */}
            {/* <GoogleLogin
              render={(renderProps) => (
                <IconButtonComponent
                  text={`${loc ? "Sign up" : "Login"} with Google`}
                  Icon={GoogleIcon}
                  onClick={renderProps.onClick}
                />
              )}
              clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
              onSuccess={(data) => {
                googleAuth(data);
              }}
              onFailure={(e) => {
                showSnackbar({
                  open: true,
                  message: e.details || "Login Failed",
                  type: "error",
                });
              }}
              cookiePolicy={"single_host_origin"}
            />
            <span style={{ height: 18 }} /> */}
            {/* Facebook */}
            {/* <FacebookLogin
              render={(renderProps) => (
                <IconButtonComponent
                  text={`${loc ? "Sign up" : "Login"} with Facebook`}
                  Icon={FacebookIcon}
                  onClick={renderProps.onClick}
                />
              )}
              appId="1110846306207988"
              autoLoad={false}
              fields="name,email,picture"
              callback={(data) => {
                facebookAuth(data);
              }}
              disableMobileRedirects
            />
          </div>
          <div
            className={`auth-overlay ${
              !authLoading ? "auth-overlay--hide" : ""
            }`}
          >
            <CircularProgress /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
