import { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RecordContext } from "../../providers/records";
import AddProject from "./add-project";
import AccountAllRecordings from "./all-recordings";

const Project = () => {
  const location = useLocation();
  const params = useParams();
  const locVal = location.pathname.split("/");
  const [setting, setSetting] = useState(false);
  const [tabs, setTabs] = useState("");
  const navigate = useNavigate();
  const { getProject, idExist, recLoading } = useContext(RecordContext);
  const { projectSettings } = getProject(params.projectid);

  useEffect(() => {
    if (!recLoading) {
      if (locVal[2] !== "add-project" && locVal[1] !== "account")
        if (!idExist(params.projectid)) navigate("/project/all-recordings");
    }
    try {
      if (locVal[locVal.length - 1] === "setting") {
        setSetting(true);
      } else {
        setSetting(false);
      }
    } catch (err) {}
  }, [params.projectid, setting, locVal[locVal.length - 1]]);

  useEffect(() => {
    setTabs("");
  }, [locVal[1], locVal[2]]);

  return (
    <>
      {setting && params.projectid !== "all-recordings" ? (
        <AddProject
          projectSettings={projectSettings}
          update={true}
          setSetting={setSetting}
          loc={params.projectid}
        />
      ) : (
        <AccountAllRecordings
          tabs={tabs}
          setTabs={setTabs}
          setSetting={setSetting}
          loc={params.projectid}
        />
      )}
    </>
  );
};

export default Project;
