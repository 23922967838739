export const url = "http://localhost:5000/api/";

export const starterList = [
  {
    title: "No. of recorded messages",
    small: 100,
  },
  {
    title: "Max recording duration allowed",
    small: "30 sec",
  },
  {
    title: "Max no. of podcasts",
    small: 1,
  },
];

export const proList = [
  {
    title: "No. of recorded messages",
    small: `1,000`,
  },
  {
    title: "Max recording duration allowed",
    small: "3 min",
  },
  {
    title: "Max no. of podcasts",
    small: 10,
  },
  {
    title: "Customize widget",
    img: true,
  },
  {
    title: "Email audio",
    img: true,
  },
  {
    title: "Remove Voiiice branding",
    img: true,
  },
];

export const premiumList = [
  {
    title: "No. of recorded messages",
    small: `5,000`,
  },
  {
    title: "Max recording duration allowed",
    small: "10 min",
  },
  {
    title: "Max no. of podcasts",
    small: "Unlimited",
  },
  {
    title: "Customize widget",
    img: true,
  },
  {
    title: "Email audio",
    img: true,
  },
  {
    title: "Remove Voiiice branding",
    img: true,
  },
];

export const prices = {
  starter: {
    mon: 5,
    mon_priceId: "price_1MgHusDnCauyQknAxzRRf8bl",
    anu: 39,
    anu_priceId: "price_1MgHusDnCauyQknAohGltK9t",
  },

  pro: {
    mon: 19,
    mon_priceId: "price_1MgHwGDnCauyQknA9l76et62",
    anu: 179,
    anu_priceId: "price_1MgHwGDnCauyQknACxhreRgZ",
  },
  premium: {
    mon: 49,
    mon_priceId: "price_1MgHxMDnCauyQknAcWteXQLU",
    anu: 479,
    anu_priceId: "price_1MgHxMDnCauyQknAzcO6JdLL",
  },
};