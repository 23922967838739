import axios from "axios";
import moment from "moment";
export const createSubscription = async (priceId, plan, planType, token) => {
  try {
    const data = { priceId, plan, planType };
    const res = await axios.post("subscription", data, {
      headers: {
        token: token,
      },
    });

    window.location.replace(res.data?.url);
  } catch (error) {
    console.log(error);
  }
};

export const startTrial = async (token) => {
  try {
    const data = {
      plan: "trial",
    };
    const res = await axios.post("subscription/trial", data, {
      headers: {
        token: token,
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkTrial = async (token) => {
  try {
    const res = await axios.get("subscription/trial", {
      headers: {
        token: token,
      },
    });

    return res?.data;
  } catch (err) {
    console.log(err);
  }
};

export const checkTrialDays = (endDate) => {
  const currentDate = moment().format();
  const a = moment(currentDate);
  const b = moment(endDate);
  return b.from(a);
};
