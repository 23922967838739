import { CircularProgress } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import WaveSurfer from "wavesurfer.js";

const formWaveSurferOptions = (controls) => ({
  container: "#rec-wave-play",
  waveColor: controls === "light" ? "#BDBDBD" : "#333333",
  progressColor: "#3864FF",
  cursorColor: "transparent",
  barWidth: 2,
  barRadius: 3,
  responsive: true,
  height: 70,
  width: "100%",
  position: "static",
  normalize: true,
  partialRender: true,
  cursor: "pointer",
  hideScrollbar: true,
  scrollParent: true,
});

function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
}

const RecWavePlay = ({ blobObj, play, setPlay, controls }) => {
  const wavesurfer = useRef(null);
  const [loading, setLoading] = useState(true);
  const [playtime, setPlaytime] = useState({ time: "00:00" });

  useEffect(() => {
    setLoading(true);
    const options = formWaveSurferOptions(controls);
    wavesurfer.current = WaveSurfer.create(options);

    return () => wavesurfer?.current?.destroy();
  }, [wavesurfer]);

  useEffect(() => {
    if (blobObj && loading) {
      wavesurfer?.current?.loadBlob(blobObj);
    }
  }, [blobObj]);

  useEffect(() => {
    wavesurfer?.current?.on("ready", function () {
      setLoading(false);
    });
    wavesurfer?.current?.on("audioprocess", function () {
      const hms = convertHMS(wavesurfer?.current?.getCurrentTime());
      if (hms !== playtime.time) setPlaytime({ time: hms });
    });
    wavesurfer?.current?.on("seek", function () {
      const hms = convertHMS(wavesurfer?.current?.getCurrentTime());
      if (hms !== playtime.time) setPlaytime({ time: hms });
    });
    wavesurfer?.current?.on("finish", function () {
      setPlay(false);
    });
    if (play) wavesurfer?.current?.play();
    else if (!play) wavesurfer?.current?.pause();
  }, [play]);

  return (
    <div className="rec-wave-play--wrapper">
      {loading && (
        <div style={{ width: 35, height: 35, display: "inline-block" }}>
          <CircularProgress
            style={{ color: "#bdbdbd", width: 35, height: 35 }}
          />
        </div>
      )}
      <div id="rec-wave-play" />
      {!loading && (
        <div
          className="rec-wave-play--timer"
          style={{
            color: controls === "light" ? "#9b9b9b" : "rgb(51, 51, 51)",
          }}
        >
          {playtime.time}
        </div>
      )}
    </div>
  );
};

export default RecWavePlay;
